import { ClaimEditValues, ClaimRow, match, UpdateMatchSubmatchData } from 'api/endpoints';
import useGraphql from 'api/graphql/useGraphql';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useMatchCodeClaimsQuery } from 'hooks/useMatchCodeClaims';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { ITrampedDialog, SourceTypeIDs } from 'pages/ClaimsContractsToolPage/hooks/useMatching';
import { getClaimsBy } from 'pages/ExhibitExporterPage/utils/apiExhibitClaim';
import { useCallback, useEffect, useState } from 'react';
import { actionStatusSet } from 'reducer/claimActionNameSelectionReducer';

export type ClaimMatchRow = Pick<
  ClaimRow,
  | 'referenceNumber'
  | 'counterpartyName'
  | 'currentSecured'
  | 'currentAdministrative'
  | 'currentPriority'
  | 'currentUnsecured'
  | 'currentTotal'
  | 'currentDebtor'
  | 'subMatchCode'
  | 'sourceType'
  | 'majorCategory'
  | 'minorCategory'
  | 'notMatched'
  | 'matchCode'
  | 'id'
>;

type Column = { title: string; field: keyof ClaimMatchRow };

export const COLUMNS: Column[] = [
  { title: 'Ref', field: 'referenceNumber' },
  { title: 'Counterparty Name', field: 'counterpartyName' },
  { title: 'Secured', field: 'currentSecured' },
  { title: 'Admin', field: 'currentAdministrative' },
  { title: 'Priority', field: 'currentPriority' },
  { title: 'Unsecured', field: 'currentUnsecured' },
  { title: 'Total', field: 'currentTotal' },
  { title: 'Current Debtor', field: 'currentDebtor' },
  { title: 'Major Category', field: 'majorCategory' },
  { title: 'Minor Category', field: 'minorCategory' },
  { title: 'Source Type', field: 'sourceType' },
  { title: 'Submatch Code', field: 'subMatchCode' },
];

export function useSingleClaimMatches(claim: ClaimEditValues) {
  const matchedClaimsQuery = useMatchCodeClaimsQuery(claim);

  const [saving, setSaving] = useState(false);
  const [matchedClaims, setMatchedClaims] = useState<ClaimMatchRow[]>();
  const [headerClaim, setHeaderClaim] = useState<ClaimMatchRow>();
  const [client] = useGraphql();
  const dispatch = useAppDispatch();

  const [trumpedMatchDialog, setTrumpedMatchDialog] = useState<ITrampedDialog>();

  useEffect(() => {
    const items = matchedClaimsQuery?.data?.claims.items ?? [];
    const headerItem = items?.find(
      (r) => r.referenceNumber === claim.referenceNumber,
    );
    setMatchedClaims(items);
    setHeaderClaim(headerItem);
  }, [claim.referenceNumber, matchedClaimsQuery?.data?.claims]);

  const updateSubMatchCode = useCallback(
    async (data: UpdateMatchSubmatchData) => {
      setSaving(true);
      await match.updateMatchSubmatch(data);
      matchedClaimsQuery.refetch();

      setSaving(false);
    },
    [matchedClaimsQuery],
  );

  const updateClaimActionStatus = useCallback(async () => {
    const resp = await getClaimsBy(client, 'id', {
      filterType: 'number',
      type: 'equals',
      filter: claim.id,
    });
    if (resp) dispatch(actionStatusSet(resp[0].actionStatus));
  }, [claim.id, client, dispatch]);

  const showTrumpedSubMatchDialog = useCallback(
    async (data: UpdateMatchSubmatchData, matchedClaims: string[]) => {
      const dialogParams = {
        matchedClaims,
        onAccept: async () => {
          closeTrumpedSubMatchDialog();
          updateSubMatchCode(data);
          await updateClaimActionStatus();
        },
        onCancel: () => {
          closeTrumpedSubMatchDialog();

          // clone items to repaint table
          setMatchedClaims((items) => _.cloneDeep(items));
        },
      };

      setTrumpedMatchDialog(dialogParams);
    },
    [updateSubMatchCode, updateClaimActionStatus],
  );

  const closeTrumpedSubMatchDialog = () => setTrumpedMatchDialog(undefined);

  const handleSubMatchCodeChange = useCallback(
    async (value: number, data: ClaimRow) => {
      const body = {
        referenceNumber: data.referenceNumber,
        matchCode: data.matchCode,
        subMatchCode: value,
        sourceTypeID: SourceTypeIDs[data.sourceType!],
        claimId: data.id,
        counterpartyName: data.counterpartyName
      }
      const trumpedBody = {
        claimId: data.id,
        matchCode: data.matchCode,
        subMatchCode: value
      };
      const matchedClaims = await match.getTrumpedSchedules(trumpedBody);

      !isEmpty(matchedClaims)
        ? showTrumpedSubMatchDialog(body, matchedClaims!)
        : updateSubMatchCode(body);
    },
    [updateSubMatchCode, showTrumpedSubMatchDialog],
  );

  return {
    headerClaim,
    matchedClaims,
    loading: matchedClaimsQuery.loading || saving,
    trumpedMatchDialog,
    handleSubMatchCodeChange,
  };
}
