import { ICellRendererParams } from 'ag-grid-community';
import { ActionStatus, ClaimRow } from 'api/endpoints';
import classNames from 'classnames';
import { SectionType } from '../types';
import { CellTextInput } from './CellTextInput';
import './MatchCodeCell.css';
import { useCallback } from 'react';

export interface MatchCodeCellParams extends ICellRendererParams<ClaimRow, any> {
  section: SectionType;
  isEditable: boolean;
  onUpdateMatchCode: (
    referenceNumber: string,
    matchCode: number,
    subMatchCode: number | null,
    sourceType: string,
    cellProps: ICellRendererParams<ClaimRow>,
    counterpartyName: string,
    claimId?: number
  ) => unknown;
  selectedRowRef?: string;
  onFetchNextMatchCode: () => Promise<string>;
  onLostFocus: () => unknown;
}

export const MatchCodeCell = ({
  section,
  isEditable,
  onUpdateMatchCode,
  onFetchNextMatchCode,
  onLostFocus,
  selectedRowRef,
  ...props
}: MatchCodeCellParams) => {
  const { data } = props;
  const key = data?.actionStatus;

  const matchCodeUpdate = useCallback((v: string) => {
    return data && data.sourceType
      && onUpdateMatchCode(
        data.referenceNumber,
        Number(v),
        data.subMatchCode || null,
        data.sourceType,
        props,
        data.counterpartyName,
        data.claimId
      )
  }, [data, onUpdateMatchCode, props]);

  if (isEditable) {
    return (
      <div className={'match-code-cell'}>
        <CellTextInput
          placeholder="Match code"
          value={`${data?.matchCode ?? ''}`}
          length={9}
          editDisabled={!!selectedRowRef}
          onChange={(v) => matchCodeUpdate(v)}
          onLostFocus={onLostFocus}
          getNextValue={onFetchNextMatchCode}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames('match-code-cell', {
        'matches-found': key === ActionStatus.MatchesFound,
        matched: key === ActionStatus.Matched,
        value: !!data?.matchCode,
      })}
    >
      {data?.matchCode ?? '- -'}
    </div>
  );
};
