import { MatchingData } from 'pages/ClaimsContractsToolPage/types';
import { ApUserRole } from 'components/RolePermission/ApUserRole';
import './ClaimsToolMatching.css'
import { MatchingGrid } from 'pages/ClaimsContractsToolPage/grid/MatchingGrid/MatchingGrid';

interface IProps {
  matchingData: MatchingData;
}

export const ClaimsToolMatching = ({
  matchingData,
}: IProps) => {
  return (
    <>
      <ApUserRole>
        <div className='matching-all--body'>
          <MatchingGrid
            gridOptions={matchingData.grid}
            dataSourceLoading={matchingData.dataSourceLoading}
          />
        </div>
      </ApUserRole>
    </>
  );
};
