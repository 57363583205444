export const SCHEDULE_SIGNATURE_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Name',
      propertyName: 'name',
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Signature',
      propertyName: 'signature',
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Position',
      propertyName: 'position',
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Date',
      propertyName: 'date',
    },

    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Other Document',
      propertyName: 'otherDocument',
    },

    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Amended Schedule',
      propertyName: 'amendedSchedule',
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
