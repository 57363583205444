import { ApLoaderDefault } from '@alixpartners/ui-components';
import { MatchingData, SectionType, SubsectionType } from './types';
import { MatchingHomePage } from './sections/ClaimsToolMatchingHomepage/MatchingHomepage';
import { ClaimsToolMatching } from './sections/ClaimsToolMatching/ClaimsToolMatching';
import { MatchingPage } from './sections/ClaimsToolMatchingExport/MatchingPage';
import { TrumpedMatchClaimPopup } from './components/TrumpedMatchClaimPopup';

export const MatchingToolBody = (props: {
  matchingData: MatchingData;
  section: SectionType;
  subsection: SubsectionType;
  onSectionClick: (section: SectionType) => unknown;
}): JSX.Element | null => {
  const { section, onSectionClick, matchingData } =
    props;
  
  const { trumpedMatchDialog } = matchingData;

  if (!matchingData) return null;

  if (!matchingData.gridColumnsReady)
    return <ApLoaderDefault className="loader-container" />;

  return (
    <>
      {section === SectionType.MatchingHomepage && (
        <MatchingHomePage handleSectionChange={onSectionClick} />
      )}
      {trumpedMatchDialog && (
        <TrumpedMatchClaimPopup
          claimId={trumpedMatchDialog.matchedClaims}
          onAccept={trumpedMatchDialog.onAccept}
          onClose={trumpedMatchDialog.onCancel}
        />
      )}
      {section === SectionType.Matching && (
        <ClaimsToolMatching
          matchingData={matchingData}
        />
      )}
      {section === SectionType.MatchingExport && <MatchingPage />}
    </>
  );
};
