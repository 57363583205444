import { SideBarDef } from 'ag-grid-community';
import { DebtorsCounterpartiesTootlip } from './components/DebtorsCounterpartiesTootlip';
import { SectionType, SubsectionType } from './types';
import { GridColumn } from 'api/endpoints';

export const SECONDARY_HEADER_SECTIONS = [
  {
    type: SubsectionType.UnmatchedClaims,
    label: 'Unmatched Claims',
  },
  {
    type: SubsectionType.AllClaims,
    label: 'All Claims',
  },
];

export const DATA_TYPE_MAP = {
  enum: 'agSetColumnFilter',
  bool: 'agSetColumnFilter',
  string: 'agTextColumnFilter',
  int: 'agNumberColumnFilter',
  decimal: 'agNumberColumnFilter',
  date: 'agDateColumnFilter',
  datetime: 'agDateColumnFilter',
};

export const PLACEHOLDERS: any = {
  referenceNumber: '',
  counterpartyName: 'Type name here',
  originalTotal: 'Number',
};

export const GENERIC_PLACEHOLDERS: any = {
  agTextColumnFilter: 'Type text',
  agNumberColumnFilter: 'Type number',
  agDateColumnFilter: 'Select date',
};

export const MONEY_FIELDS = [
  'currentTotal',
  'originalSecured',
  'originalTotal',
  'currentSecured',
  'proposedSecured',
  'proposedTotal',
  'alixPartnersSecured',
  'alixPartnersTotal',
  'clientSecured',
  'clientTotal',
  'counselSecured',
  'counselTotal',
  'highSecured',
  'highTotal',
  'lowSecured',
  'lowTotal',
  'porSecured',
  'porTotal',
  'rejectionDamageLow',
  'rejectionDamagesHigh',
  'assumptionCureAmount',
  'negotiatedCureAmount',
  'contractValue',
  'fYSpend',
  'lastTwelveMonthsSpend',
  'totalMatchPrePetitionValue',
  'totalMatchPrePetitionContractValue',
  'cureCostReductionAmount',
  'twelveMonthSavings',
  'oneTimeSavings',
];

// @todo: update column names and remove unneccessary when contract
// feature is ready
export const PRIMARY_COLUMN = 'id';
export const REFERENCE_NUMBER_COLUMN = 'referenceNumber';
export const COUNTERPARTY_NAME_COLUMN = 'counterpartyName';
export const ACTION_STATUS_COLUMN = 'actionStatus';
export const ACTION_REVIEW_COLUMN = 'actionReview';
export const MATCH_CODE_COLUMN = 'matchCode';
export const SOURCE_TYPE_COLUMN = 'sourceType';
export const CURRENT_TOTAL_COLUMN = 'currentTotal';
export const MAJOR_CATEGORY_COLUMN = 'majorCategory';
export const MINOR_CATEGORY_COLUMN = 'minorCategory';
export const CLAIM_REVIEWER_COLUMN = 'claimReviewers';
export const SUB_MATCH_CODE_COLUMN = 'subMatchCode';
export const MATCH_SCORE_CELL = 'matchScore';
export const COMPANY_CODE_COLUMN = 'companyCode';
export const DEBTOR_COLUMN = 'debtor';
export const CURRENT_DEBTOR_COLUMN = 'currentDebtor';
export const CATEGORY_COLUMN = 'category';
export const HAS_MULTIPLE_DEBTORS_COLUMN = 'hasMultipleDebtors';
export const HAS_MULTIPLE_COUNTERPARTIES_COLUMN = 'hasMultipleCounterparties';
export const HAS_MULTIPLE_MATCH_CODES = 'hasMultipleMatchCodes';
export const COUNTERPARTY_NAMES_COLUMN = 'counterpartyNames';
export const PRIMARY_DEBTOR_COLUMN = 'primaryDebtor';
export const MATCH_CODES_COLUMN = 'matchCodes';
export const RES_TYPE_COLUMN = 'actionExhibitTypeName';
export const RES_SUBTYPE_COLUMN = 'actionSubStatusName';
export const PROPOSED_TOTAL_COLUMN = 'proposedTotal';
export const ORIGINAL_DEBTOR_COLUMN = 'originalDebtor';
export const RES_NAME_COLUMN = 'actionName';
export const ADDRESS_COLUMN = 'address';

export const CONTRACT_TYPE_COLUMN = 'contractType';
export const CONTRACT_RES_TYPE_COLUMN = 'contractActionTypeName';
export const CONTRACT_RES_SUBTYPE_COLUMN = 'contractActionSubStatusName';
export const CONTRACT_DATE_COLUMN = 'contractDate';
export const CONTRACT_VALUE_COLUMN = 'contractValue';
export const CONTRACT_ACTION_STATUS_COLUMN = 'contractStatus';
export const CONTRACT_NEGOCIATED_CURE_COLUMN = 'negotiatedCureAmount';

export const NOT_MATCHED_CELL = 'notMatched';
export const NUMBER_OF_MATCHES_CELL = 'numberOfMatches';
export const CREATED_DATE_CELL = 'createdDate';
export const ONE_HUNDRED_PERCENT_MATCH_CODE_CELL = 'oneHundredPercentMatchCode';
export const HAS_ONE_HUNDRED_PERCENT_MATCH_CELL = 'hasOneHundredPercentMatch';

export const DO_NOT_DISPLAY_COLUMNS = [NOT_MATCHED_CELL];

export const MATCH_SCORE_COLUMN_POSITION_ORDER = 6;

export const PIN_COLUMN = 'pin';
export const FORMATTING_CURRENCY = 'currency';

export const CLAIMS_CONSTANT_COLUMNS = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
];

export const CLAIMS_DEFAULT_DISPLAY = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
  SOURCE_TYPE_COLUMN,
  MATCH_CODE_COLUMN,
  SUB_MATCH_CODE_COLUMN,
  ACTION_STATUS_COLUMN,
  RES_TYPE_COLUMN,
  RES_SUBTYPE_COLUMN,
  RES_NAME_COLUMN,
  CURRENT_TOTAL_COLUMN,
  PROPOSED_TOTAL_COLUMN,
  ORIGINAL_DEBTOR_COLUMN,
  MAJOR_CATEGORY_COLUMN,
];

export const MATCHING_DEFAULT_DISPLAY = [
  SOURCE_TYPE_COLUMN,
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
  MATCH_CODE_COLUMN,
  SUB_MATCH_CODE_COLUMN,
  ADDRESS_COLUMN,
  CURRENT_DEBTOR_COLUMN,
  CURRENT_TOTAL_COLUMN,
  MAJOR_CATEGORY_COLUMN,
  MINOR_CATEGORY_COLUMN,
]

export const CONTRACTS_CONSTANT_COLUMNS = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
];

export const CONTRACTS_DEFAULT_DISPLAY = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
  CATEGORY_COLUMN,
  CONTRACT_TYPE_COLUMN,
  MATCH_CODE_COLUMN,
  CONTRACT_ACTION_STATUS_COLUMN,
  CONTRACT_RES_TYPE_COLUMN,
  CONTRACT_RES_SUBTYPE_COLUMN,
  PRIMARY_DEBTOR_COLUMN,
  CONTRACT_DATE_COLUMN,
  CONTRACT_NEGOCIATED_CURE_COLUMN,
];

export const DEFAULT_DISPLAY_MAP: {
  [key: string]: string[];
} = {
  [SectionType.Claims]: CLAIMS_DEFAULT_DISPLAY,
  [SectionType.Matching]: MATCHING_DEFAULT_DISPLAY,
  [SectionType.Contracts]: CONTRACTS_DEFAULT_DISPLAY,
};

export const SECTIONS_WITH_PREDEFINED_FILTERS = [
  SectionType.Claims,
  SectionType.Contracts,
];

export const DEFAULT_COL_DEF = {
  // disable for the moment, to save and use resized col width from cache
  //flex: 1,
  minWidth: 75,
  resizable: true,
  floatingFilter: true,
  tooltipComponent: DebtorsCounterpartiesTootlip,
};

export const DEFAULT_COL_DEF_CONTRACTS = {
  // disable for the moment, to save and use resized col width from cache
  //flex: 1,
  minWidth: 75,
  resizable: true,
  floatingFilter: true,
  tooltipComponent: DebtorsCounterpartiesTootlip,
};

export const SIDE_BAR_CONFIG: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnExpandAll: true,
        suppressColumnMove: true,
      },
    },
  ],
  defaultToolPanel: '',
  position: 'right',
};

export const GRID_HEADER_HEIGHT = 34;
export const GRID_HEADER_HEIGHT_MATCHING = 40;
export const GRID_FLOATING_FILTER_HEIGHT = GRID_HEADER_HEIGHT;

export const NEGATIVE_NUM_FORMAT = '_(#,##0_);_((#,##0);_("-"??_);_(@_)';

export const gridColumns = [
  {
    "id": 1,
    "propertyName": "sourceType",
    "displayName": "Source Type",
    "dataType": "enum",
    "displayIn": undefined,
    "allowedValues": [
        "ScheduledClaim",
        "FiledClaim",
        "Contract"
    ],
    "customField": false,
    "enabled": true
  },
  {
    "id": 2,
    "propertyName": "referenceNumber",
    "displayName": "Ref. Number",
    "dataType": "string",
    "displayIn": null,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 3,
    "propertyName": "counterpartyName",
    "displayName": "Counterparty Name",
    "dataType": "string",
    "displayIn": undefined,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 6,
    "propertyName": "matchCode",
    "displayName": "Match Code",
    "dataType": "int",
    "displayIn": undefined,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 7,
    "propertyName": "subMatchCode",
    "displayName": "Sub-match Code",
    "dataType": "int",
    "displayIn": undefined,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 8,
    "propertyName": "address",
    "displayName": "Address",
    "dataType": "string",
    "displayIn": undefined,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 19,
    "propertyName": "currentDebtor",
    "displayName": "Current Debtor",
    "dataType": "string",
    "displayIn": null,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 37,
    "propertyName": "currentTotal",
    "displayName": "Current Total",
    "dataType": "decimal",
    "displayIn": null,
    "allowedValues": null,
    "customField": false,
    "enabled": true
  },
  {
    "id": 96,
    "propertyName": "majorCategory",
    "displayName": "Major Category",
    "dataType": "enum",
    "displayIn": undefined,
    "allowedValues": [
        "Accounts Payable",
        "Debt",
        "Secured Claims",
        "Employee",
        "Insurance",
        "Customer",
        "pension",
        "trade",
        "unsecured notes",
        "abl facility",
        "intercompany-debtors",
        "intercompany-non debtors",
        "lease - equipment",
        "lease - real estate",
        "lender",
        "other secured debt",
        "TestingMiha13",
        "123",
        "major",
        "bondholder",
        "government non-tax",
        "indemnification",
        "new mjr categ",
        "cash management",
        "cash management",
        "502(b)(test)",
        "501",
        "500",
        "Priority Claims",
        "Rejection Damages",
        "Secured Debt",
        "Tax Audits",
        "GUC Partial Claims",
        "test1",
        "Contract Assumption",
        "GUC Only Claims",
        "Intercompany Anagram",
        "Intercompany Non Debtor",
        "Ordinary Course Tax Claims",
        "Placeholder Claims",
        "Stock Holding",
        "Other",
        "503(b)(9)",
        "Admin Claims",
        "Assumption_Real Property Leases",
        "Claimant holds Collateral",
        "Equity",
        "Tax",
        "Insufficient Documentation",
        "Uncategorized",
        "Voided",
        "Unsecured Debt",
        "Contract",
        "Guarantees",
        "Intercompany",
        "Litigation",
        "Royalties",
        "Regulatory"
    ],
    "customField": false,
    "enabled": true
  },
  {
    "id": 97,
    "propertyName": "minorCategory",
    "displayName": "Minor Category",
    "dataType": "enum",
    "displayIn": undefined,
    "allowedValues": [
        "Trade Payables",
        "Utilities",
        "503(b)(9)",
        "Reclamation",
        "Leases",
        "Post-Petition",
        "Workers Compensation",
        "Unknown",
        "Other",
        "AdminClaimsCategory",
        "Individual",
        "Uncashed Check - Individual",
        "Unclaimed Property",
        "Unclaimed Property - Individual",
        "Notes",
        "Utilities",
        "Refunds",
        "Retirement Benefits",
        "Individual",
        "Surety",
        "Tax",
        "Accounts Payables",
        "Non-PI Tort (Non-Govt/Non-Individual)",
        "PI Tort (Governmental)",
        "PI Tort (Individual)",
        "PI Tort (Non-Govt/Non-Individual)",
        "Non-PI Tort (Third Party Payor)",
        "Employee",
        "Other",
        "Other",
        "Letter of Credit",
        "Lienholder",
        "Non-PI Tort (Governmental)",
        "Non-PI Tort (Individual)",
        "Other",
        "Individual",
        "Other",
        "Other",
        "Other",
        "Other",
        "Individual",
        "Customer",
        "Other",
        "Other",
        "Individual",
        "Other",
        "edit345",
        "Minor",
        "Individual",
        "Trustee",
        "Individual",
        "Other",
        "Miha Test12313",
        "Miha Test6",
        "Testing miha",
        "#@@%3C12^&>asd{}[]",
        "1123#3c##!ASDd",
        "EDT345",
        "testy",
        "Testing 1 2 3",
        "2test 2a",
        "test",
        "new",
        "edit3",
        "test",
        "minor category",
        "12 new mn categ",
        "new mnr categ",
        "new minor categ",
        "test",
        "Sales and Use Tax",
        "Other",
        "Surety Bonds",
        "Contract - Real Property",
        "Other223454",
        "test2",
        "ABL facility",
        "FILO facility due 2026",
        "Fixed Rate Notes due Feb 2026",
        "Floating rate notes due 2025",
        "Income Tax",
        "Other",
        "Property leases",
        "Severance",
        "Equipment leases",
        "Other",
        "Property leases",
        "Deposit",
        "Contract",
        "Employee",
        "Equipment leases",
        "Insurance",
        "Litigation",
        "Other",
        "Property leases",
        "Equity",
        "Non Debtor",
        "Other",
        "2023 Personal Property Tax",
        "Other",
        "LC",
        "Other",
        "Surety Bond",
        "Contract",
        "Leases",
        "Other",
        "Leases",
        "Other",
        "Payables",
        "Property leases",
        "Other",
        "Cash Collateral",
        "Leasess new mi",
        "Other",
        "Payables",
        "Contract",
        "Equipment leases",
        "Insurance",
        "Sales & Use and Property Taxes",
        "Unemployment Insurance",
        "Tax",
        "Claims",
        "Claims",
        "Unknown",
        "Sales and Witholding Taxes",
        "Corporate Income Taxes",
        "Council Tax",
        "Gross Receipts, Wage Witholdings, Workers Comp",
        "Amusement Tax",
        "Parking Tax",
        "Other Taxes and Fees",
        "Commercial Activity",
        "Withholding Taxes",
        "Corporate and Franchise Taxes",
        "Withholding and Franchise Taxes",
        "Corporate and Withholding Taxes",
        "Equity Interests",
        "Franchise Taxes",
        "Sales and Use Taxes",
        "Income and Withholding Taxes",
        "Property Taxes",
        "Canadian Goods and Services Taxes",
        "Employee Matters",
        "Employee Class Action",
        "Employee - Discrimination or Wrongful Termination",
        "Fair Labor Standards Act",
        "OSHA",
        "Royalty Interests",
        "Landlords",
        "Telecommunications",
        "Guarantee Claims",
        "Intercompany - Debtor",
        "Intercompany - NonDebtor",
        "Litigation",
        "Contract/Executory Contract",
        "Facility Lease",
        "Customer Agreement",
        "Gift Cards",
        "Equipment Lease",
        "Real Estate",
        "Benefit Provider",
        "Executive Pension Plan - Active",
        "Executive Pension Plan - Retired",
        "No Supporting Documentation",
        "Retirement Benefits (non-specific)",
        "Insurance",
        "Convertible Bonds",
        "Settlement Facility",
        "Employee Claim",
        "Executive Pension Plan",
        "Executive Retirement Plan",
        "401k",
        "Leases",
        "Transportation",
        "Reclam - 503b9",
        "Secured Debt",
        "Other Secured Debt",
        "Mechanics Lien",
        "Other",
        "Other",
        "Other",
        "Other",
        "Lienholders",
        "Reclamation/Trade Claim",
        "Environmental",
        "Unclaimed Property",
        "Import Export",
        "Foreign",
        "Excise",
        "Business Licenses, Permits and Fees",
        "Franchise",
        "Sales and Use",
        "Income",
        "Real and Personal Property",
        "Payroll Taxes",
        "Withholding",
        "Other",
        "Other",
        "Federal",
        "State",
        "Other",
        "Other",
        "Product Liability",
        "Personal Injury",
        "Environmental",
        "Class Action",
        "Protective",
        "Potential Lawsuit",
        "Non Debtor",
        "Joint Venture",
        "Employee",
        "Workers Compensation",
        "Indemnification",
        "General Liability",
        "Rejection Damages",
        "Protective",
        "Anticipatory Contract Rejection",
        "Other",
        "Other",
        "Debtor",
        "Professional Services",
        "Information Technology",
        "Insurance",
        "Employment Agreements",
        "Independent Contractors",
        "Breach",
        "Customer",
        "Equipment",
        "Royalties",
        "Vendor",
        "Benefits",
        "Intercompany",
        "Discounts and Rebates",
        "Loyalty Programs",
        "CoMarketing",
        "Third Parties",
        "Other",
        "Real Property",
        "Workers Comp Claim",
        "Other",
        "Other",
        "Deposits",
        "Warranties",
        "Returns",
        "Benefits",
        "Benefits Provider",
        "Expense Report",
        "PBGC Claims",
        "PTO/Vacation",
        "Severance",
        "Wages",
        "Deferred Compensation Plan",
        "Pension Plan",
        "Retirement Plan",
        "HR Contract",
        "Union",
        "UCC Liens",
        "Letters of Credit",
        "Capital Leases",
        "Surety Bonds",
        "Mechanics Lien",
        "Other",
        "Purchasing Card",
        "Professional Services",
        "Environmental",
        "Other",
        "Secured",
        "Unsecured"
    ],
    "customField": false,
    "enabled": true
  }
] as GridColumn[];

export const HIDDEN_INPUT_COLUMNS = [
  REFERENCE_NUMBER_COLUMN,
  MATCH_CODE_COLUMN,
  SUB_MATCH_CODE_COLUMN,
  CURRENT_TOTAL_COLUMN
];

export const MATCHING_GRID_HEADER_HEIGHT = 34;
