import {
  GetRowIdParams,
  GridApi,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import setPlaceholders from '../util/setPlaceholders';

import { ClaimRow } from 'api/endpoints';

import { gridColumns, PRIMARY_COLUMN } from '../const';
import {
  ExtendedGridOptions,
  MatchingData,
  SectionType,
  SubsectionType,
} from '../types';
import useGridState from './useGridState';
import useMatching from './useMatching';

import useGraphql from 'api/graphql/useGraphql';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useClaimsColumnDef } from './claim/useClaimsColumnDef';
import { createMatchingDatasource } from '../lazyLoading/matchingDataSource';
import { load } from './useClaimsData';

function useMatchingData(
  section: SectionType,
  subsection: SubsectionType,
): MatchingData {
  const state = useAppSelector((state) => state.claims);
  const loading = state.loading;

  const dispatch = useAppDispatch();

  const ref = useRef<AgGridReact>(null);
  const [searchText, setSearchText] = useState('');

  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>();

  const [dataSourceLoading, setDataSourceLoading] = useState(false);

  // this variable is false until grid is rendered and all dependencies are loaded
  // expect it to turn true after loading all claims table data dependencies
  // and never turn false after that
  const gridRenderedAndDependenciesLoaded = ready && !loading;

  const [client] = useGraphql();

  const reloadGridData = async () => {
    client?.resetStore();
    ref.current?.api?.refreshServerSide({ route: [] });
  };

  const matching = useMatching(
    ref,
    section,
    subsection,
    [] as ClaimRow[],
    reloadGridData,
  );

  const {
    columnState,
    onFirstDataRendered,
  } = useGridState({
    gridRef: ref,
    section,
    gridColumns: gridColumns,
    ready: gridRenderedAndDependenciesLoaded,
    refreshData: reloadGridData,
    sort: false,
  });

  const columnDefs = useClaimsColumnDef(
    gridColumns,
    section,
    subsection,
    columnState,
    false,
    {
      ...matching.columnDefsEvents,
    },
    matching.selectedRowRef
  );

  const updatePlaceholders = useCallback(
    () => setPlaceholders(columnDefs),
    [columnDefs],
  );

  const dataSource = useMemo(
    () =>
      createMatchingDatasource({
        subsection,
        client,
        setDataSourceLoading,
        searchText,
        gridColumns,
      }),

    // let's update datasource if gridColumns change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section, subsection, gridColumns, client, searchText],
  );

  // loader
  useEffect(() => {
    load(dispatch)();
  }, [dispatch]);

  useEffect(() => {
    // grid is rerendered on section change so we
    // need to set ready to false
    setReady(false);
  }, [section, gridApi]);

  const onGridReady = (params: { api: GridApi }): void => {
    params.api.sizeColumnsToFit();
    setGridApi(params.api);
    setReady(true);
  };

  const grid: ExtendedGridOptions = {
    ref,
    onFirstDataRendered,
    pagination: true,
    columnDefs,
    maintainColumnOrder: false,
    serverSideDatasource: dataSource,
    suppressClipboardApi: true,
    suppressClipboardPaste: true,
    ...matching.gridOptions,
    getRowId: useCallback(
      (params: GetRowIdParams) => params.data[PRIMARY_COLUMN],
      [],
    ),
    onGridReady,
    onViewportChanged: updatePlaceholders,
    onBodyScroll: updatePlaceholders,
  };

  return {
    loading,
    gridColumns,
    gridColumnsReady: !!gridColumns,
    grid,
    searchText,
    trumpedMatchDialog: matching.trumpedMatchDialog,
    onSearchChanged: setSearchText,
    refresh: () => {
      load(dispatch)();
      reloadGridData();
    },
    dataSourceLoading,
  };
}

export default useMatchingData;
