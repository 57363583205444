import { ActionName, ClaimRow, exhibits } from 'api/endpoints';
import Excel from 'exceljs';
import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { formatDate, US_DATE_FORMAT } from 'utils/formatDate';
import { getClaimNumber } from '../utils/field';
import { IExcelCreator } from './excelExporter';
import {
  createTotalRowStyle,
  defCellStyle,
  defPositionNumberStyle,
} from './utils/excelConst';
import {
  createExcelWorkbook,
  ExcelDocumentHeader,
} from './utils/excelCreateWorkbook';
import { excelAddPageHeader, HeaderPositionCell } from './utils/excelHeader';
import {
  deleteObjectionCommentaryColumn,
  excelAddPageFooter,
  excelAddRow,
  excelAddTableHeaderRow,
  excelAddTotalRow,
} from './utils/excelUtils';
import {
  createClaimAmount,
  createHeaderFooter,
  getAddressLine,
  getChildDataByRefNumber,
  getTotalCurrency,
} from './utils/exporterUtils';
import { IExhibitWorkbookData, IRowValues, TRowStyle } from './utils/type';

const COLUMN_LIST: ExcelDocumentHeader[] = [
  { header: '', width: 4 },
  { header: 'Original creditor', width: 20 },
  { header: 'Claim transferred to', width: 10.1 },
  { header: 'Claim number', width: 10.1 },
  { header: 'Original Debtor', width: 17.8 },
  { header: 'Proposed Debtor', width: 17.8 },
  { header: 'Date filed/scheduled', width: 10 },
  { header: 'Claim amount and priority (1)', width: 13 },
  { header: 'Basis for objection', width: 15 },
  { header: 'Comments', width: 15 },
];

const valuesStyle: TRowStyle = [
  { ...defPositionNumberStyle }, //'positionNumber',
  { ...defCellStyle }, //'originalCreditor',
  { ...defCellStyle }, //'claimTransferredTo',
  { ...defCellStyle }, //'referenceNumber',
  { ...defCellStyle }, //'originalDebtor',
  { ...defCellStyle }, //'proposedDebtor',
  {
    ...defCellStyle,
    numFmt: 'mm-dd-yy',
    alignment: { horizontal: 'center', vertical: 'top' },
  }, //'officialClaimDate',
  {
    ...defCellStyle,
    numFmt: '[$$-409]#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  }, //'claimAmount',
  { ...defCellStyle }, //'objectionBasis',
  { ...defCellStyle }, //'objectionCommentary',
];

const valuesTotalStyle = createTotalRowStyle(valuesStyle.length);

const objectionColId = 10;

const PAGE_HEADER_POS: HeaderPositionCell = {
  left: 1,
  middle: 4,
  right: 7,
  length: 10,
};

export const templateAlterDebtor: IExcelCreator = {
  fileName: 'Alter_Debtor.xlsx',
  getWorkbook: async (exhibitState: IExhibitState, actionName: ActionName) => {
    const childClaims = (await exhibits.getChildClaims(actionName.id)) ?? [];
    const excelData = getDataToShow(exhibitState, actionName, childClaims);
    return await createWorkbook(excelData, exhibitState.displayObjection);
  },
};

async function createWorkbook(
  data: IExhibitWorkbookData,
  displayObjection: boolean | undefined,
): Promise<Excel.Workbook> {
  const workbook = createExcelWorkbook('Sheet1', COLUMN_LIST);
  const firstSheet = workbook.getWorksheet(1);

  excelAddPageHeader(firstSheet, data.headerFooter.header, PAGE_HEADER_POS);
  excelAddTableHeaderRow(firstSheet, COLUMN_LIST);
  excelAddPageFooter(firstSheet, data.headerFooter);

  data.claimsList.forEach((row) => excelAddRow(firstSheet, row, valuesStyle));
  excelAddTotalRow(firstSheet, data.totalRow, valuesTotalStyle);

  deleteObjectionCommentaryColumn(firstSheet, displayObjection, objectionColId);
  return workbook;
}

function getDataToShow(
  data: IExhibitState,
  actionName: ActionName,
  childClaims: ClaimRow[],
): IExhibitWorkbookData {
  const { structureFooterOptions } = data;

  // here is a bug. `r.value` is string type. But in real it contains an integer value
  const showClaimCount = !!structureFooterOptions.find((r) => r.value == '3'); // eslint-disable-line eqeqeq

  // we need to remove parent claims when we calculate totals
  // we get the totals from its child claims
  const filteredClaims = data.claims.filter(
    (claim) =>
      !childClaims.find((c) => c.parentClaimNumber === claim.referenceNumber),
  );

  return {
    headerFooter: createHeaderFooter(data, actionName),
    claimsList: getPreparedClaimsData(
      data.claims,
      data.displayObjection,
      data.objectionBasis,
      childClaims,
    ),
    totalRow: getTotalRow([...filteredClaims, ...childClaims], showClaimCount),
  };
}

function getTotalRow(claims: ClaimRow[], showClaimCount: boolean): IRowValues {
  const values = [
    '', //positionNumber
    'Claims to be modified totals', //originalCreditor
    '', //claimTransferredTo
    showClaimCount ? `Count: ${claims.length}` : '', //referenceNumber
    '', //originalDebtor
    '', //proposedDebtor
    '', //officialClaimDate
    getTotalCurrency(claims, 'currentTotal'), //claimAmount
    '', //objectionBasis
  ];
  return { values };
}

const getPreparedClaimsData = (
  claims: ClaimRow[],
  displayObjection: boolean | undefined,
  objectionBasis: string,
  childClaims: ClaimRow[],
) => {
  return claims.map((claim, index) => {
    const child = getChildDataByRefNumber(childClaims, claim.referenceNumber);
    return {
      values: [
        index + 1, //positionNumber
        getAddressLine(claim), //originalCreditor
        '', //claimTransferredTo
        getClaimNumber(claim), //referenceNumber
        child.originalDebtors ?? claim.originalDebtor, //originalDebtor
        child.proposedDebtors ?? claim.proposedDebtor, //proposedDebtor
        formatDate(claim.officialClaimDate, US_DATE_FORMAT), //officialClaimDate
        child.claimAmounts ?? createClaimAmount(claim), //claimAmount
        objectionBasis, //objectionBasis
        displayObjection ? claim.objectionCommentary : '', //objectionCommentary
      ],
    } as IRowValues;
  });
};
