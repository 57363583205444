import { ApplyColumnStateParams, ColDef } from 'ag-grid-community';
import { ClaimRow, GridColumn } from 'api/endpoints';
import { useMemo } from 'react';

import { SectionType, SubsectionType } from '../../types';

import compact from 'lodash/compact';

import { isUndefined } from 'lodash';
import { sortColumnsByState } from '../columnsSortingUtils';
import {
  ClaimsColumnDefsCallbacks,
  claimsGridColumnMapper,
  claimsStaticColumnDef,
} from './claimsGridColumnMapper';

export const useClaimsColumnDef = (
  gridColumns: GridColumn[] | undefined,
  section: SectionType,
  subsection: SubsectionType,
  columnState: ApplyColumnStateParams | undefined,
  selectAllCheckbox: boolean,
  callbacks: ClaimsColumnDefsCallbacks,
  selectedRowRef?: string,
) => {
  const columnDefs: ColDef<ClaimRow>[] = useMemo(() => {
    if (isUndefined(gridColumns)) return [];
    const isMatching = section === SectionType.Matching;

    const staticColDef = claimsStaticColumnDef(
      section,
      subsection,
      selectAllCheckbox,
      callbacks,
    );

    const columnMapperFn = claimsGridColumnMapper(section, subsection, callbacks, selectedRowRef);
    const columnDef = compact(gridColumns?.map(columnMapperFn));
    let result = [...staticColDef, ...columnDef];

    if (!isMatching) result = sortColumnsByState(result, columnState);

    return result;
  }, [gridColumns, subsection, section, callbacks, selectAllCheckbox, columnState, selectedRowRef]);

  return columnDefs;
};
