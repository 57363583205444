import {
  ActionStatus,
  ClaimRow,
  ContractRow,
  Counterparty,
  match,
  UpdateMatchData,
  UpdateMatchSubmatchData,
} from 'api/endpoints';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MATCH_CODE_COLUMN, SUB_MATCH_CODE_COLUMN } from '../const';
import { ExtendedGridOptions, SectionType, SubsectionType } from '../types';

import {
  CellClickedEvent,
  CellKeyPressEvent,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import useGraphql from 'api/graphql/useGraphql';
import { ApThreeWayValue } from 'components/ApThreeWayToggleSwitch';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  matchApproved,
  matchDeclined,
  oneHundrenPercentMatchesApproved,
} from 'reducer/claimsReducer';
import { ActionStatusCellParams } from '../components/ActionStatusCell';
import agGridCellSelector from '../util/agGridCellSelector';

export const SourceTypeIDs: {[key: string]: number} = {
  ScheduledClaim: 1,
  FiledClaim: 2,
  Contract: 4
}

export interface MatchingColumnDefsCallbacks {
  onMatchApprove: (event: any, cellProps: ActionStatusCellParams) => any;
  onMatchDecline: (event: any, cellProps: ActionStatusCellParams) => any;
  onUpdateMatchCode: (
    id: number,
    matchCode: number,
    contract?: boolean,
    cellProps?: ICellRendererParams<ClaimRow | ContractRow, any>,
  ) => any;
  onUpdateSubmatchCode?: (
    referenceNumber: string,
    matchCode: number,
    subMatchCode: number | null,
    counterpartyName: string,
    sourceType: string,
    cellProps: ICellRendererParams<ClaimRow>,
    claimId: number,
  ) => any;
  onUpdateMatchSubMatchCode?: (
    referenceNumber: string,
    matchCode: number,
    subMatchCode: number | null,
    sourceType: string,
    cellProps: ICellRendererParams<ClaimRow>,
    counterpartyName: string,
    claimId: number,
  ) => any;
  onFetchNextMatchCode?: () => Promise<string>;
}

export interface ITrampedDialog {
  matchedClaims?: string[];
  matchedContracts?: string[];
  onAccept: () => void;
  onCancel: () => void;
}

export interface MatchingHook {
  gridOptions: ExtendedGridOptions | undefined;
  claimsExpandState: [
    ApThreeWayValue,
    React.Dispatch<React.SetStateAction<ApThreeWayValue>>,
  ];
  trumpedMatchDialog: ITrampedDialog | undefined;
  onMatchGroupSelectedClaims?: () => void;
  onAcceptMatches?: () => void;
  onRowExpansion: (event: CellClickedEvent<ClaimRow>) => void;
  columnDefsEvents: MatchingColumnDefsCallbacks;
  selectedRowRef?: string;
}

/**
 * This hook handles all the logic for matching claims. Here we define callbacks for matching and changes to grid-data
 * and calling endpoints to update server data
 * @param ref
 * @param section
 * @param subsection
 * @param selectedRows
 * @param refocusSelection
 * @param reloadGridData
 * @returns
 */
export default function useMatching(
  ref: React.RefObject<AgGridReact<any>>,
  section: SectionType,
  subsection: SubsectionType,
  selectedRows: ClaimRow[],
  reloadGridData: () => void,
  refocusSelection?: () => void,
): MatchingHook {
  const dispatch = useAppDispatch();
  const [client] = useGraphql();

  const state = useAppSelector((state) => state.claims);

  const [trumpedMatchDialog, setTrumpedMatchDialog] = useState<ITrampedDialog>();
  const claimsExpandState = useState(ApThreeWayValue.No);
  const [expandValue, setExpandValue] = claimsExpandState;
  const [selectedRowRef, setSelectedRowRef] = useState<string | undefined>();

  useEffect(() => {
    if (!ref.current?.api) return;
    if (expandValue === ApThreeWayValue.No) {
      ref.current.api.collapseAll();
    }
    if (expandValue === ApThreeWayValue.Yes) {
      ref.current.api.expandAll();
    }
  }, [expandValue, ref]);

  const onMatchApprove = useCallback(
    async (event: any, cellProps: ActionStatusCellParams | CellKeyPressEvent) => {
      if (!cellProps?.node?.parent) return;
      if (cellProps.node.parent.data.actionStatus === ActionStatus.MatchingComplete)
        return;

      const { claimId, counterpartyId } = event;
      const matchCode = event.matchCode;
      const claim = state.data.counterparties.find((c) => c.claimId === claimId);
      if (!claim) return;
      const matchCounterparty = claim?.matches.find(
        (m) => m.counterpartyId === counterpartyId,
      );
      // if matchStatus is already defined, it means we already matches the guy
      if (matchCounterparty?.matchStatus) return;

      await match.approveMatch({ claimId, matchCode });
      client.resetStore();

      dispatch(matchApproved({ claimId, counterpartyId }));
      if (!ref.current?.api) return;

      ref.current.api.refreshServerSide({ route: [claimId] });

      // we need to set a match code to `claimId` of the parent
      const newData = {
        ...cellProps.node.parent.data,
        actionStatus: ActionStatus.MatchingComplete,
        matchCode: event.matchCode,
      };
      cellProps.node.parent.setData(newData as ClaimRow);
      refocusSelection?.();
    },
    [client, dispatch, ref, refocusSelection, state.data.counterparties],
  );
  const onMatchDecline = useCallback(
    async (event: any, cellProps: ActionStatusCellParams | CellKeyPressEvent) => {
      const { claimId, counterpartyId } = event;
      if (!cellProps?.node?.parent) return;
      if (cellProps.node.parent.data.actionStatus === ActionStatus.MatchingComplete)
        return;

      const claim = state.data.counterparties.find((c) => c.claimId === claimId);
      if (!claim) return;
      const matchCounterparty = claim?.matches.find(
        (m) => m.counterpartyId === counterpartyId,
      );
      // if matchStatus is already defined, it means we already matches the guy
      if (matchCounterparty?.matchStatus) return;

      const numberOfMatches: number | undefined = (
        cellProps.node.parent.data as ClaimRow
      ).numberOfMatches;
      const newNumberOfMatches = numberOfMatches ? numberOfMatches - 1 : 0;
      const newData = {
        ...cellProps.node.parent.data,
        numberOfMatches: newNumberOfMatches,
      } as ClaimRow;

      if (newNumberOfMatches <= 0) {
        // this is the last match, we should send reject all
        await match.rejectAll(claimId);
        client.resetStore();
      } else {
        // there are other matches to decline
        await match.rejectMatch(counterpartyId);
      }
      cellProps.node.parent.setData(newData);

      dispatch(matchDeclined({ claimId, counterpartyId }));

      ref.current?.api.refreshServerSide({ route: [claimId] });
      refocusSelection?.();
    },
    [client, dispatch, ref, refocusSelection, state.data.counterparties],
  );

  const onRowExpansion = useCallback(
    (event: CellClickedEvent<ClaimRow> | CellKeyPressEvent<ClaimRow>) => {
      if (!event.node.isExpandable()) return;
      if (event.colDef.field === MATCH_CODE_COLUMN) return;
      event.node.setExpanded(!event.node.expanded);
      if (!ref.current?.api) return;
      let areAllExpanded = true;
      let areAllCollapsed = true;
      // detect when all rows are collapsed or all
      // rows are expanded and set correct value
      // for the switch
      ref.current.api.forEachNode((n) => {
        if (!n.isExpandable()) return;
        if (n.level !== 0) return;
        areAllExpanded = n.expanded && areAllExpanded;
        areAllCollapsed = !n.expanded && areAllCollapsed;
      });
      if (areAllExpanded) {
        setExpandValue(ApThreeWayValue.Yes);
        return;
      }
      if (areAllCollapsed) {
        setExpandValue(ApThreeWayValue.No);
        return;
      }

      setExpandValue(ApThreeWayValue.Unknown);
    },
    [setExpandValue, ref],
  );

  const acceptMatchesByIds = useCallback(
    async (claimIds: number[]) => {
      await match.approveHundredPercentMatches(claimIds);
      if (!ref?.current?.api) return;
      client.resetStore();

      dispatch(oneHundrenPercentMatchesApproved({ claimIds }));

      const nodes = ref.current.api
        .getRenderedNodes()
        .filter((n) => claimIds.includes(n.data.id));
      nodes.forEach((node) => {
        node.setData({
          ...node.data,
          matchCode: node.data.oneHundredPercentMatchCode,
          actionStatus: ActionStatus.MatchingComplete,
        });
        ref.current?.api.refreshServerSide({ route: [node.data.id] });
      });
    },
    [client, dispatch, ref],
  );

  const onCellKeyPress = useCallback(
    async (event: CellKeyPressEvent<ClaimRow | Counterparty>) => {
      const keyboardEvent = event.event as KeyboardEvent;
      const data = event.data;
      if (!keyboardEvent) return;

      if (
        [MATCH_CODE_COLUMN, SUB_MATCH_CODE_COLUMN].includes(
          event.column.getColId(),
        ) &&
        keyboardEvent.key === 'Enter'
      ) {
        const colId = event.column.getColId();
        const matchCodeElement = agGridCellSelector(event.rowIndex, colId);
        if (!matchCodeElement) return;

        const inputElement = matchCodeElement.getElementsByTagName('input')[0];
        if (document.activeElement === inputElement) {
          inputElement.click();
        } else {
          inputElement.focus();
        }
        return;
      }
      if (keyboardEvent.key === 'e') {
        if (!event.node.isExpandable()) return;
        // only ClaimRow is expandable
        onRowExpansion(event as CellKeyPressEvent<ClaimRow>);
        return;
      }

      // always check if event.node.parent exists
      if (!event.node.parent?.data) {
        const data = event.node.data as ClaimRow;
        if (data.hasOneHundredPercentMatch && keyboardEvent.key === 'v') {
          // this is v click on a claim that has one hunded percent match
          await acceptMatchesByIds([data.id]);
        }

        return;
      }
      if (keyboardEvent.key === 'c') {
        onMatchDecline(data, event);
      }
      if (keyboardEvent.key === 'v') {
        onMatchApprove(data, event);
      }
    },
    [onMatchApprove, onMatchDecline, onRowExpansion, acceptMatchesByIds],
  );

  const gridOptions = useMemo(() => {
    if (section !== SectionType.Matching) return;

    return {
      // note that treeData is not reactive
      // https://github.com/ag-grid/ag-grid/issues/4155
      // so we will have to rerender the grid in useEffect using key
      key: `${section}${subsection}`,
      groupDisplayType: "custom",
      onVirtualRowRemoved: (e) => setSelectedRowRef(undefined),
      getDataPath: (data) => data.path,
      // defines whether to display a "tree" expansion button on the left
      onCellKeyPress,
    } as ExtendedGridOptions;
  }, [subsection, section, onCellKeyPress, setSelectedRowRef]);

  const onUpdateMatchCode = useCallback(
    async (
      claimId: number,
      matchCode: number,
      contract: boolean = false,
      cellProps?: ICellRendererParams<ClaimRow | ContractRow>,
    ) => {
      if (matchCode === null) {
        // if match code is null, we should delete the match code
        await match.postNotMatch(claimId);
      } else {
        if (contract) {
          const contractId = claimId;
          await match.manualContractMatch([{ contractId, matchCode }]);
        } else {
          await match.manualMatch([{ claimId, matchCode }]);
        }
      }

      if (cellProps) {
        updateGridRowNode(cellProps.node, { matchCode: matchCode ?? 0 });
      }

      reloadGridData();
      refocusSelection?.();
    },
    [refocusSelection, reloadGridData],
  );

  const onFetchNextMatchCode = useCallback(async () => {
    const data = await match.getNewMatchcode();
    if (data) {
      return `${data.id}`;
    }
    return `${Math.floor(999999 * Math.random() * 100)}`;
  }, []);

  const onAcceptMatches = useCallback(async () => {
    const claimIds = selectedRows.map((c) => c.id);
    await acceptMatchesByIds(claimIds);
  }, [selectedRows, acceptMatchesByIds]);

  const updateSubMatchCode = useCallback(
    async (data: UpdateMatchData, cellProps: ICellRendererParams<ClaimRow>) => {
      await match.updateMatch(data);
      const subMatchCode = data.subMatchCode ?? undefined;
      updateGridRowNode(cellProps.node, { subMatchCode });
      refocusSelection?.();
    },
    [refocusSelection],
  );

  const showTrumpedSubMatchDialog = useCallback(
    async (
      data: UpdateMatchSubmatchData,
      matchedClaims: string[],
      cellProps: ICellRendererParams<ClaimRow>,
    ) => {
      const dialogParams = {
        matchedClaims,
        onAccept: async () => {
          closeTrumpedSubMatchDialog();
          await match.updateMatchSubmatch(data);
          const subMatchCode = data.subMatchCode ?? undefined;
          updateGridRowNode(cellProps.node, { subMatchCode });
          reloadGridData();
          refocusSelection?.();
        },
        onCancel: () => {
          closeTrumpedSubMatchDialog();
          ref.current?.api.redrawRows();
        },
      };
      setTrumpedMatchDialog(dialogParams);
    },
    [ref, refocusSelection, reloadGridData],
  );

  const closeTrumpedSubMatchDialog = () => setTrumpedMatchDialog(undefined);

  const onUpdateSubmatchCode = useCallback(
    async (
      referenceNumber: string,
      matchCode: number,
      subMatchCode: number | null,
      counterpartyName: string,
      sourceType: string,
      cellProps: ICellRendererParams<ClaimRow>,
      claimId: number,
    ) => {
      const trumpedBody = { claimId, matchCode, subMatchCode }
      const matchedClaims = await match.getTrumpedSchedules(trumpedBody);
      updateGridRowNode(cellProps.node, { subMatchCode: subMatchCode || undefined });
      reloadGridData();
      const data = { referenceNumber, matchCode, subMatchCode, counterpartyName, sourceTypeID: SourceTypeIDs[sourceType]};

      !isEmpty(matchedClaims)
        ? showTrumpedSubMatchDialog(data, matchedClaims!, cellProps)
        : updateSubMatchCode(data, cellProps);
    },
    [updateSubMatchCode, showTrumpedSubMatchDialog, reloadGridData],
  );

  const updateMatchSubMatchCode = useCallback(
    async (data: UpdateMatchSubmatchData, cellProps: ICellRendererParams<ClaimRow>, reloadGridData: () => void) => {
      const code = data.subMatchCode === null ? { matchCode: data.matchCode } : { subMatchCode: data.subMatchCode };
      await match.updateMatchSubmatch(data);
      updateGridRowNode(cellProps.node, code);
      refocusSelection?.();
      reloadGridData();
    },
    [refocusSelection],
  );

  const onUpdateMatchSubMatchCode = useCallback(
    async (
      referenceNumber: string,
      matchCode: number,
      subMatchCode: number | null,
      sourceTypeID: string,
      cellProps: ICellRendererParams<ClaimRow>,
      counterpartyName: string,
      claimId: number,
    ) => {
      const body = { referenceNumber, matchCode, subMatchCode, sourceTypeID: SourceTypeIDs[sourceTypeID], counterpartyName };
      setSelectedRowRef(cellProps.node.id);
      if (sourceTypeID !== 'Contract') {
        const trumpedBody = { claimId, matchCode, subMatchCode }
        const matchedClaims = await match.getTrumpedSchedules(trumpedBody);
        if (!isEmpty(matchedClaims)) return showTrumpedSubMatchDialog(body, matchedClaims!, cellProps);
      }
      return updateMatchSubMatchCode(body, cellProps, reloadGridData);
  }, [updateMatchSubMatchCode, showTrumpedSubMatchDialog, reloadGridData]);

  return {
    claimsExpandState,
    gridOptions,
    trumpedMatchDialog,
    onRowExpansion,
    onAcceptMatches: selectedRows.length ? onAcceptMatches : undefined,
    columnDefsEvents: {
      onMatchApprove,
      onMatchDecline,
      onUpdateMatchCode,
      onUpdateSubmatchCode,
      onFetchNextMatchCode,
      onUpdateMatchSubMatchCode
    },
    selectedRowRef,
  };
}

const updateGridRowNode = (
  node: RowNode<ClaimRow | ContractRow>,
  value: Partial<ClaimRow | ContractRow>,
) => {
  if (!node.data) return;
  node.setData({ ...node.data, ...value });
};
