import { IServerSideDatasource } from 'ag-grid-community';
import { GraphQlApolloClient } from 'api/graphql';
import { createGraphQLQueryFromParams } from 'utils/agGridHotchocolate/createGraphQLQueryFromParams';
import {
  IServerSideGetRowsParamsWithFilterModel,
} from 'utils/agGridHotchocolate/types';
import { getRequestColumns } from '../../../utils/columns';
import { SubsectionType } from '../types';
import datify from '../util/datify';
import { GridColumn } from 'api/endpoints';

type DataSourceProps = {
  subsection: SubsectionType;
  client: GraphQlApolloClient;
  setDataSourceLoading: (val: boolean) => void;
  searchText: string;
  gridColumns: GridColumn[];
};

const graphqlEntity = 'counterPartyMatches';
const requestColumnsVariableName = 'items';
const totalCountVariableName = 'totalCount';

export const createMatchingDatasource = ({
  client,
  setDataSourceLoading,
  searchText,
  gridColumns,
}: DataSourceProps): IServerSideDatasource => {

  return {
    // called by the grid when more rows are required
    getRows: async (params: IServerSideGetRowsParamsWithFilterModel) => {

      if (!gridColumns) return;
      setDataSourceLoading(true);

      // get data for request from server
      const requestColumns = getRequestColumns(
        params,
        gridColumns,
        ['claimId'],
      );

      const booleanColumns = gridColumns
        .filter((c) => c.dataType === 'bool')
        .map((c) => c.propertyName);

      const { query, variables } = createGraphQLQueryFromParams(
        params.request,
        requestColumns,
        {
          queryName: 'CounterPartyMatches',
          graphqlEntity: graphqlEntity,
          booleanColumns,
          gridColumns,
          searchText,
        },
        undefined,
      );

      const response = await client.query({ query, variables });

      const data = response.data;

      if (data) {
        const counterpartyMatches = data[graphqlEntity][requestColumnsVariableName];
        const rowData = datify(counterpartyMatches, gridColumns);

        const rowCount = data[graphqlEntity][totalCountVariableName];

        // supply rows for requested block to grid
        params.success({
          rowData,
          rowCount,
        });
      } else {
        // inform grid request failed
        params.fail();
      }
      setDataSourceLoading(false);
    },
  };
};
