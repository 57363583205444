import { useMemo, useState } from 'react';
import useClaimsData from './hooks/useClaimsData';
import useContractsData from './hooks/useContractsData';

import { ClaimsToolHeader } from 'components/ClaimsToolHeader/ClaimsToolHeader';

import { isEmpty } from 'lodash';
import { Dashboard } from 'pages/Dashboard';
import { useClaimToolQueryParams } from 'utils/hooks/useClaimToolQueryParams';
import './ClaimsContractsToolPage.css';
import { ClaimsToolBody } from './ClaimsToolBody';
import { ContractsToolBody } from './ContractsToolBody';
import { ClaimsReports } from './sections/ClaimsReports/ClaimsReports';
import { ExhibitExport } from './sections/ExhibitsExport/ExhibitsExport';
import { SectionType, SubsectionType } from './types';
import { DocumentsPage } from 'pages/DocumentsPage/DocumentsPage';
import { Layout } from 'components/Layout';
import classNames from 'classnames';
import useMatchingData from './hooks/useMatchingData';
import { MatchingToolBody } from './MatchingToolBody';

export const NON_STICKY_SECTIONS = [
  SectionType.Claims,
  SectionType.Contracts,
  SectionType.Matching,
  SectionType.Dashboard,
  SectionType.Vendors,
];

export const ClaimsContractsToolPage = () => {
  const [claimsCount, setClaimsCount] = useState<number | undefined>();
  const [contractsCount, setContractsCount] = useState<number | undefined>();

  const { section, subsection, setSection, setSubsection } =
    useClaimToolQueryParams();

  const footerIsSticky = useMemo(
    () => !NON_STICKY_SECTIONS.includes(section),
    [section],
  );

  const claimsData = useClaimsData(section, subsection, setClaimsCount);
  const contractsData = useContractsData(section, subsection, setContractsCount);
  const matchingData = useMatchingData(section, subsection);

  const sectionData = useMemo(() => {
    switch (section) {
      case SectionType.Claims:
        return claimsData;
      case SectionType.Contracts:
        return contractsData;
    }

    return undefined;
  }, [section, claimsData, contractsData]);

  const hasBackNavButton = useMemo(
    () =>
      SectionType.Documents === section &&
      (subsection === SubsectionType.AllClaimsDocuments ||
        subsection === SubsectionType.AllContractsDocuments),
    [section, subsection],
  );

  return (
    <Layout stickyFooter={footerIsSticky}>
      <div
        className={classNames('claims-contracts-tool', {
          'claims-contracts-tool__dashboard': section === SectionType.Dashboard,
        })}
      >
        <ClaimsToolHeader
          section={section}
          onSectionClick={setSection}
          onResetFilters={sectionData?.onResetFilters}
          onExport={sectionData?.onExport}
          isExporting={sectionData?.isExporting}
          hasBackNavButton={hasBackNavButton}
        />
        {SectionType.Dashboard === section && <Dashboard />}
        {SectionType.Claims === section && (
          <ClaimsToolBody
            claimsCount={claimsCount}
            section={section}
            subsection={subsection}
            onSectionClick={setSection}
            claimsData={claimsData}
            setSubsection={setSubsection}
            disableResetFilters={isEmpty(
              sectionData?.grid?.ref?.current?.api?.getFilterModel(),
            )}
            onResetFilters={claimsData?.onResetFilters}
          />
        )}

        {[
          SectionType.Matching,
          SectionType.MatchingExport,
          SectionType.MatchingHomepage,
        ].includes(section) && (
          <MatchingToolBody
            section={section}
            subsection={subsection}
            onSectionClick={setSection}
            matchingData={matchingData}
          />
        )}

        {SectionType.Documents === section && <DocumentsPage />}

        {SectionType.Contracts === section && (
          <ContractsToolBody
            contractsCount={contractsCount}
            subsection={subsection}
            disableResetFilters={isEmpty(
              sectionData?.grid?.ref?.current?.api?.getFilterModel(),
            )}
            contractsData={contractsData}
            setSubsection={setSubsection}
            onResetFilters={contractsData?.onResetFilters}
          />
        )}
        
        {SectionType.ExhibitsExport === section && <ExhibitExport />}
        {SectionType.Reports === section && <ClaimsReports />}
      </div>
    </Layout>
  );
};
