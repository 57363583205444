import { acquireToken } from '@alixpartners/ui-utils';
import DOMPurify from 'dompurify';
import { showHttpSingleError } from './httpErrorAlert';
import { IHttpError, ResponseType, THttpMethod } from './requestTypes';

const { REACT_APP_API_URL } = process.env;

export interface IResult<T> {
  data?: T;
  error?: IHttpError;
}

enum ResponseDataType {
  BLOB = 'BLOB',
}

export type CustomErrorMap = { [key: number]: IHttpError };

const DEFAULT_ERROR_TITLE: IHttpError = { title: 'File download failed' };

export const downloadFile = (
  url: string,
  fileName: string,
  customError?: CustomErrorMap,
) => downloadRequest(url, fileName, customError);

export const downloadFilePost = (url: string, data: any) =>
  apiFetchWithBody(url, data, 'POST', ResponseDataType.BLOB);

export const downloadFileGet = (url: string) =>
  apiFetchWithBody<Blob, string, string>(url, null, 'GET', ResponseDataType.BLOB);

export const downloadBlobStorageFile = (url: string, fileName: string) =>
  downloadBlobStorageFileRequest(url, fileName);

export const get = <T>(url: string) => apiRequestWithMessage<T>(url);

export const del = (url: string, data?: any) => apiRequestWithMessage(url, data, 'DELETE');
export const post = <T>(url: string, data: any = undefined) =>
  apiRequestWithMessage<T>(url, data, 'POST');
export const put = <T>(url: string, data: any) =>
  apiRequestWithMessage<T>(url, data, 'PUT');

// Requests without HTTP Error Message
export const getQuiet = <T>(url: string) => apiFetch<T>(url);
export const delQuiet = (url: string) => apiFetch(url, undefined, 'DELETE');
export const postQuiet = (url: string, data: any) => apiFetch(url, data, 'POST');
export const putQuiet = (url: string, data: any) => apiFetch(url, data, 'PUT');

//Get text value
export const getText = async (url: string) => {
  const result = await apiFetchText(url);
  if (result.error) showHttpSingleError(result.error);

  return result.data;
};

export const getTextQuiet = (url: string) => apiFetchText(url);

export const postUploadFile = <T>(url: string, data: any) =>
  apiUploadFile<T>(url, data, 'POST');

// ---------------------------------------------------------------------
// --------------------- PRIVATE METHODS -------------------------------
// ---------------------------------------------------------------------

const downloadRequest = async (
  url: string,
  fileName: string,
  customError?: CustomErrorMap,
) => {
  try {
    const stream = await fetchData(url, 'GET');
    if (stream.status !== 200) {
      showHttpSingleError(customError?.[stream.status] ?? DEFAULT_ERROR_TITLE);
      return;
    }

    const blob = await stream.blob();
    downloadURI(blob, fileName);
  } catch (error: any) {
    //log error into the console
    console.error(error, url);

    showHttpSingleError({
      title: 'Download failed please contact your IT administrator',
      message: error?.message,
    });
  }
};

const downloadBlobStorageFileRequest = async (url: string, fileName: string) => {
  try {
    const stream = await fetch(`${url}`);
    if (stream.status !== 200) {
      showHttpSingleError({
        code: stream.status,
        title: 'Download failed',
      });
      return;
    }

    const blob = await stream.blob();
    downloadURI(blob, fileName);
  } catch (error: any) {
    //log error into the console
    console.error(JSON.stringify(error), `URL: ${encodeURIComponent(url)}`);

    showHttpSingleError({
      title: 'Download failed please contact your IT administrator',
      message: error?.message,
    });
  }
};

const apiRequestWithMessage = async <T>(
  url: string,
  data: any = undefined,
  method: THttpMethod = 'GET',
): Promise<T | undefined> => {
  const result = await apiFetch<T>(url, data, method);
  if (result.error) {
    showHttpSingleError(result.error);
  }

  return result.data;
};

const apiFetchWithBody = async <TOk, T400, T404>(
  url: string,
  data: any = undefined,
  method: THttpMethod = 'GET',
  responseDataType?: ResponseDataType,
): Promise<ResponseType<TOk, T400, T404>> => {
  try {
    const stream = await fetchData(url, method, data);
    const { status } = stream;

    if (status < 100 || status >= 500) {
      // unexpected server internal error, the details should not be shown to the user,
      // nor does the client logic need to handle this apart from a generic message
      return {
        type: 'internal-server',
        status,
      };
    }

    // should be safe to have a json body if request is not 5XX or 1XX
    // but BE returns sometimes json sometimes text, TODO discuss to normalize
    let body = null;
    try {
      if (responseDataType === 'BLOB') {
        body = await stream.blob();
      } else {
        body = await stream.json();
      }
    } catch (_e) {}

    if (status >= 400) {
      // bad request, client sent wrong data
      if (status === 400) {
        return {
          type: 'bad-request-400',
          status,
          error: body as T400,
        };
      }
      if (status === 404) {
        return {
          type: 'bad-request-404',
          status,
          error: body as T404,
        };
      }
      return {
        type: 'bad-request',
        status,
        error: body,
      };
    }

    return {
      type: 'ok',
      status,
      data: body as TOk,
    };
  } catch (error: any) {
    // any other error, usually we arrive here from a fetch error (no connection)
    console.error(
      'Error in apiFetchWithBody: ',
      JSON.stringify(error),
      `URL: ${encodeURIComponent(url)}`,
    );

    return {
      type: 'fetch-error',
      error: {
        message: error?.message,
      },
    };
  }
};

const apiFetch = async <T = {}>(
  url: string,
  data: any = undefined,
  method: THttpMethod = 'GET',
): Promise<IResult<T>> => {
  try {
    const stream = await fetchData(url, method, data);

    if (stream.status < 200 || stream.status >= 400) {
      const body = await getStreamJson(stream);
      return { error: { code: stream.status, title: 'Request error', body } };
    }

    const returnData = await stream.text();
    if (!returnData.length) return { data: {} as T };

    try {
      return { data: JSON.parse(returnData) as T };
    } catch (jsonError: any) {
      console.error(
        'Error parsing json from endpoint ',
        url,
        jsonError,
        'Request BE to switch to JSON format',
      );
    }
    return { data: {} as T };
  } catch (error: any) {
    //log error into the console
    console.error(error, url);

    return {
      error: {
        title: 'Request Failed please contact your IT administrator',
        message: error?.message,
      },
    };
  }
};

const apiFetchText = async (
  url: string,
  data: any = undefined,
  method: THttpMethod = 'GET',
): Promise<IResult<string>> => {
  try {
    const stream = await fetchData(url, method, data);

    if (stream.status < 200 || stream.status >= 400) {
      const body = await getStreamJson(stream);
      return { error: { code: stream.status, title: 'Request error', body } };
    }

    const returnData = await stream.text();
    return { data: returnData };
  } catch (error: any) {
    //log error into the console
    console.error(error, url);

    return {
      error: {
        title: 'Request Failed please contact your IT administrator',
        message: error?.message,
      },
    };
  }
};

const apiUploadFile = async <T = {}>(
  url: string,
  data: any = undefined,
  method: THttpMethod = 'GET',
): Promise<IResult<T>> => {
  try {
    const header = {
      headers: {
        Authorization: `Bearer ${await acquireToken()}`,
      },
      method,
      body: data ?? undefined,
    };

    const stream = await fetch(`${REACT_APP_API_URL}${url}`, header);

    if (stream.status < 200 || stream.status >= 400) {
      const body = await getStreamJson(stream);
      return { error: { code: stream.status, title: 'Request error', body } };
    }

    const body = await getStreamJson(stream);
    return { data: body as T };
  } catch (error: any) {
    //log error into the console
    console.error(error, url);

    return {
      error: {
        title: 'Request Failed please contact your IT administrator',
        message: error?.message,
      },
    };
  }
};

const fetchData = async (
  url: string,
  method: THttpMethod = 'GET',
  data: any = undefined,
) => {
  const token = await acquireToken();

  const header = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method,
    body: data ? JSON.stringify(data) : undefined,
  };

  return await fetch(`${REACT_APP_API_URL}${url}`, header);
};

const downloadURI = (blob: Blob, name: string) => {
  var urlObject = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.download = name;
  link.href = DOMPurify.sanitize(urlObject);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(urlObject);
  });
};

const getStreamJson = async (stream: Response) => {
  try {
    const bodyJson = await stream.json();
    return bodyJson;
  } catch (error: any) {
    console.error('JSON parsing error: ', error?.message);
  }

  return undefined;
};
