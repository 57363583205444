import { CheckboxChangedEvent, ExcelExportParams, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import {
  ClaimRow,
  Counterparty,
  CounterpartyRowWithPathId,
  GridColumn,
} from 'api/endpoints';
import { ContractRow } from 'api/endpoints/contracts';
import { ApThreeWayValue } from 'components/ApThreeWayToggleSwitch';
import { ITrampedDialog } from '../hooks/useMatching';

export enum SectionType {
  NONE = 'None',
  Dashboard = 'Dashboard',
  Claims = 'Claims',
  Contracts = 'Contracts',
  Matching = 'Matching',
  /** MatchingExport is a temporary tab for 1-2 releases */
  MatchingExport = 'MatchingExport',
  MatchingHomepage = 'MatchingHomepage',
  Reports = 'Reports',
  CasesEntities = 'CasesEntities',
  ExhibitsExport = 'ExhibitsExport',
  SOFAS = 'Sofas',
  Documents = 'Documents',
  Vendors = 'Vendors',
}

export enum GridColumnsSectionType {
  Claim = 'claim',
  Contracts = 'contract',
}

export enum SubsectionType {
  UnmatchedClaims = 'UnmatchedClaims',
  AllClaims = 'AllClaims',
  SOFAS = 'Sofas',
  AllClaimsDocuments = 'AllClaimsDocuments',
  AllContractsDocuments = 'AllContractsDocuments',
  AllVendors = 'AllVendors',
}

export interface ExtendedGridOptions extends GridOptions {
  ref?: React.RefObject<AgGridReact>;
  key?: string;
  onCheckboxChanged?(event: CheckboxChangedEvent): void;
}

export interface ExportExcelArgs {
  allColumns?: boolean;
  auditLogs?: boolean;
}
type ClaimsContractsCommonTypes = {
  count?: number;
  gridColumns?: GridColumn[];
  gridColumnsReady: boolean;
  loading: boolean;
  isExporting: boolean;
  grid: ExtendedGridOptions;
  onSearchChanged: (value: string) => void;
  refresh: () => void;
  searchText: string;
  onResetFilters?: () => unknown;
  onAcceptMatches?: () => unknown;
  exportFileName?: string;
  dataSourceLoading?: boolean;
};

export type ClaimsData = ClaimsContractsCommonTypes & {
  summaryMatchingDialog: TSummaryMatchDialog;
  claimsExpandState: [
    ApThreeWayValue,
    React.Dispatch<React.SetStateAction<ApThreeWayValue>>,
  ];
  trumpedMatchDialog: ITrampedDialog | undefined;
  showMineClaims: boolean;
  onShowMineClaimsChange: (v: boolean) => void;
  onMatchGroupSelectedClaims?: () => unknown;
  onExport: (params: ExportExcelArgs) => void;
  selectedRows: ClaimRow[];
};

export type MatchingData = {
  loading: boolean;
  gridColumns?: GridColumn[];
  gridColumnsReady?: boolean;
  grid: ExtendedGridOptions;
  trumpedMatchDialog: ITrampedDialog | undefined;
  refresh: () => void;
  dataSourceLoading: boolean;
  onSearchChanged: (value: string) => void;
  searchText: string;
}

export type ContractsData = ClaimsContractsCommonTypes & {
  showMineContracts: boolean;
  onShowMineContractsChange: (v: boolean) => void;
  onMatchGroupSelectedContracts?: () => unknown;
  onExport: (params: ExcelExportParams) => void;
  selectedRows: ContractRow[];
};

export type MatchingRow =
  | (Omit<ClaimRow, 'id'> & { id: string; path?: string[] })
  | (Omit<Counterparty, 'id'> & { id: string; path?: string[] });

export interface ClaimsDataState {
  gridColumns?: GridColumn[];
  counterparties: CounterpartyRowWithPathId[];
}

export interface ContractsDataState {
  gridColumns?: GridColumn[];
  counterparties: CounterpartyRowWithPathId[];
  relatedContracts?: number;
}

export type TSummaryMatchDialog = {
  show: boolean;
  data: ClaimRow | undefined;
  close: () => void;
};

export type ClaimsContractsAdditionalExtensions =
  | '.rtf'
  | '.bmp'
  | '.s'
  | '.a'
  | '.xlsm'
  | '.tif'
  | '.txt'
  | '.DOTX';
