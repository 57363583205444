import './SubMatchCodeCell_.css';
import { ICellRendererParams } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { CellTextInput } from './CellTextInput';
import { useCallback } from 'react';
export interface SubMatchCodeCellParams extends ICellRendererParams<ClaimRow, any> {
  onUpdateSubmatchCode: (
    referenceNumber: string,
    matchCode: number,
    subMatchCode: number | null,
    sourceType: string,
    cellProps: ICellRendererParams<ClaimRow>,
    counterpartyName: string,
    claimId?: number,
  ) => unknown;
  selectedRowRef?: string;
  onLostFocus: () => unknown;
  isEditable: boolean;
}

export const SubMatchCodeCell = ({
  isEditable,
  onUpdateSubmatchCode,
  onLostFocus,
  selectedRowRef,
  ...props
}: SubMatchCodeCellParams) => {
  const { data } = props;

  const subMatchCodeUpdate = useCallback((v: string) => {
    return data && data.sourceType
      && onUpdateSubmatchCode(
          data.referenceNumber,
          data.matchCode, Number(v),
          data.sourceType,
          props,
          data.counterpartyName,
          data.claimId
        )
  }, [data, onUpdateSubmatchCode, props]);

  if (!data?.matchCode) return <div>- -</div>;
  if (isEditable && data.sourceType !== 'Contract') {
    return (
      <div className={'submatch-code-cell'}>
        <CellTextInput
          placeholder="Submatch code"
          length={9}
          editDisabled={!!selectedRowRef}
          value={`${data?.subMatchCode ?? ''}`}
          onChange={(v) => subMatchCodeUpdate(v)}
          onLostFocus={onLostFocus}
        />
      </div>
    );
  }

  return <div className={'submatch-code-cell'}>{data?.subMatchCode ?? '-'}</div>;
};
