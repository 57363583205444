import {
  CellClickedEvent,
  CellDoubleClickedEvent,
  CellKeyDownEvent,
  GetRowIdParams,
  GridApi,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { claimsFetch, claimsLoaded } from 'reducer/claimsReducer';
import setPlaceholders from '../util/setPlaceholders';
import useRefClick from './claim/useRefClick';
import usePinRows from './usePinRows';

import { ClaimRow, configuration, match } from 'api/endpoints';

import { PRIMARY_COLUMN } from '../const';
import {
  ClaimsData,
  ExportExcelArgs,
  ExtendedGridOptions,
  GridColumnsSectionType,
  SectionType,
  SubsectionType,
} from '../types';
import useGridState from './useGridState';
import useMatching from './useMatching';

import { DynamicField } from 'api/endpoints/dynamic-fields';
import useGraphql from 'api/graphql/useGraphql';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { setExportState } from 'reducer/claimsContractsExportReducer';
import { DEFUALT_INPUT, dynamicFieldsActions } from 'reducer/dynamicFields';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import excelExport from '../../../utils/excelExport';
import { createDatasource } from '../lazyLoading/dataSource';
import agGridCellSelector from '../util/agGridCellSelector';
import { selectAllCheckboxEnabled } from '../util/selectAllCheckboxEnabled';
import { setSavedResizedColumnWidth } from '../util/setSavedResizedColumnWidth';
import { useClaimsColumnDef } from './claim/useClaimsColumnDef';
import { useExportBackgroundJob } from './useExportBackgroundJob';
import { useMatchSummaryDialogWindow } from './useMatchSummaryDialogWindow';
import { useResetFilters } from './useResetFilters';
import { useSelectionManager } from './useSelectionManager';

export const load = (dispatch: any) => async () => {
  dispatch(claimsFetch({}));

  const [gridColumns, counterparties] = await Promise.all([
    configuration.getGridColumns(GridColumnsSectionType.Claim),
    match.getMatches(),
  ]);

  // We need to filter only enabled dynamic fields
  const filteredGridColumns = gridColumns?.filter(
    (el) => !(el.propertyName.includes('custom') && !el.enabled),
  );

  const dynamicFields =
    gridColumns
      ?.filter((col) => col.propertyName.startsWith('custom'))
      ?.map((col) => {
        return {
          id: col.id,
          enabled: col.enabled,
          type: col.dataType,
          name: col.displayName,
          propertyName: col.propertyName,
          displayIn: col.displayIn,
        } as DynamicField;
      }) ?? [];

  dispatch(
    claimsLoaded({
      gridColumns: filteredGridColumns,
      counterparties,
    }),
  );
  dispatch(
    dynamicFieldsActions.done({
      items: dynamicFields,
      input: DEFUALT_INPUT,
      section: SectionType.Claims,
    }),
  );
  dispatch(
    dynamicFieldsActions.setAll({
      items: dynamicFields,
      section: SectionType.Claims,
    }),
  );
};

function useClaimsData(
  section: SectionType,
  subsection: SubsectionType,
  setDisplayCount: (count: number) => unknown,
): ClaimsData {
  const state = useAppSelector((state) => state.claims);
  const loading = state.loading;

  const dispatch = useAppDispatch();

  const ref = useRef<AgGridReact>(null);
  const [searchText, setSearchText] = useState('');

  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>();

  const [lastSelectedCell, setLastSelectedCell] =
    useState<{ rowIndex: number; colId: string } | undefined>();
  const [dataSourceLoading, setDataSourceLoading] = useState(false);

  const { generating, fileName } = useExportBackgroundJob(SectionType.Claims);

  const { isClient = false } = useAppUserType();
  // this variable is false until grid is rendered and all dependencies are loaded
  // expect it to turn true after loading all claims table data dependencies
  // and never turn false after that
  const gridRenderedAndDependenciesLoaded = ready && !loading;

  // this variable might turn false when additional data is loaded (such as pagination or filtering)
  // otherwise it should be true
  const gridDataSourceReady =
    gridRenderedAndDependenciesLoaded && !dataSourceLoading;

  const {
    selectedRows,
    selectAllCheckbox,
    onSelectionChanged,
    handleCheckboxChange,
    onPaginationChanged,
  } = useSelectionManager<ClaimRow>(ref, gridDataSourceReady);

  const [filterModel, setFilterModel] = useState<any>({});

  const { gridColumns } = state.data || {};

  const [client] = useGraphql();

  const handleCellKeyDown = useCallback((e: CellKeyDownEvent) => {
    if (e.rowIndex === null) return;
    setLastSelectedCell({
      rowIndex: e.rowIndex,
      colId: e.column.getColId(),
    });
  }, []);
  const refocusSelection = useCallback(() => {
    if (!lastSelectedCell) return;
    const { rowIndex, colId } = lastSelectedCell;
    const element = agGridCellSelector(rowIndex, colId);
    if (!element) return;
    element.focus();
  }, [lastSelectedCell]);

  const reloadGridData = async () => {
    client?.resetStore();
    ref.current?.api?.refreshServerSide({ route: [] });
  };

  const matching = useMatching(
    ref,
    section,
    subsection,
    selectedRows,
    reloadGridData,
    refocusSelection,
  );

  const {
    columnState,
    showMine: showMineClaims,
    setShowMine: setShowMineClaims,
    onDisplayedColumnsChanged,
    onBodyScrollEnd,
    onFirstDataRendered,
    onFilterChanged,
  } = useGridState({
    gridRef: ref,
    section,
    gridColumns,
    ready: gridRenderedAndDependenciesLoaded,
    refreshData: reloadGridData,
    sort: true,
  });

  const columnDefs = useClaimsColumnDef(
    gridColumns,
    section,
    subsection,
    columnState,
    selectAllCheckbox,
    {
      refocusSelection,
      onCheckboxChange: handleCheckboxChange,
      ...matching.columnDefsEvents,
    },
  );

  const updatePlaceholders = useCallback(
    () => setPlaceholders(columnDefs),
    [columnDefs],
  );

  const dataSource = useMemo(
    () =>
      createDatasource({
        section,
        subsection,
        showMine: showMineClaims,
        client,
        setDataSourceLoading,
        isClient,
        setDisplayCount,
        searchText,
      }),

    // let's update datasource if gridColumns change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section, subsection, gridColumns, client, showMineClaims, searchText],
  );

  const { pinnedTopRowData, onPinCellClickedInjector } =
    usePinRows<ClaimRow>(section);
  const { onRefCellClickedInjector } = useRefClick(section, subsection);
  const { summaryMatchingDialog, onMatchCellClickedInjector } =
    useMatchSummaryDialogWindow(section, subsection);

  const onExport = useCallback(
    async ({ allColumns, auditLogs }: ExportExcelArgs) => {
      if (!ref?.current?.columnApi) return;
      if (!gridColumns) return;

      const fileName = `${allColumns ? 'All claims' : 'Claims'} table export`;

      const resp = await excelExport({
        filterModel,
        columnApi: ref?.current?.columnApi,
        gridColumns,
        allColumns,
        auditLogs,
        section,
        endRow: 50000,
      });
      if (resp && resp.jobId) {
        dispatch(
          setExportState({ generating: true, fileName, jobId: resp.jobId, section }),
        );
      }
    },
    [ref, filterModel, gridColumns, section, dispatch],
  );

  // loader
  useEffect(() => {
    load(dispatch)();
  }, [dispatch]);

  useEffect(() => {
    // grid is rerendered on section change so we
    // need to set ready to false
    setReady(false);
  }, [section, gridApi]);

  useEffect(() => {
    if (!ref?.current?.api) return;
    ref.current.api.deselectAll();
  }, [subsection]);

  const onResetFilters = useResetFilters(ref, section, setShowMineClaims);

  const onGridReady = (params: { api: GridApi }): void => {
    setGridApi(params.api);
    setReady(true);
  };

  useEffect(() => {
    if (dataSourceLoading) {
      setDisplayCount(-1);
    }
  }, [showMineClaims, dataSourceLoading, setDisplayCount, gridApi]);

  const grid: ExtendedGridOptions = {
    ref,
    pagination: selectAllCheckboxEnabled(section, subsection),
    onPaginationChanged,
    onBodyScrollEnd,
    onFirstDataRendered,
    pinnedTopRowData,
    columnDefs,
    maintainColumnOrder: false,
    serverSideDatasource: dataSource,
    suppressClipboardApi: true,
    suppressClipboardPaste: true,
    ...matching.gridOptions,
    // isServerSideGroupOpenByDefault: true,
    getRowId: useCallback(
      (params: GetRowIdParams) => params.data[PRIMARY_COLUMN],
      [],
    ),
    onFilterChanged,
    onModelUpdated: (event: any) => {
      if (dataSourceLoading) return;
      if (!ref?.current?.api) return;
      setFilterModel(ref.current.api.getFilterModel());
    },
    onGridReady,
    onSelectionChanged,
    onViewportChanged: updatePlaceholders,
    onBodyScroll: updatePlaceholders,
    onDisplayedColumnsChanged,
    // when match code cell is editable, we need to open the matches on double click
    onCellDoubleClicked: (event: CellDoubleClickedEvent<ClaimRow>) => {
      onMatchCellClickedInjector(event);
    },
    onCellClicked: (event: CellClickedEvent<ClaimRow>) => {
      onRefCellClickedInjector(event);
      onPinCellClickedInjector(event);
      matching.onRowExpansion(event);
    },
    onCellKeyDown: handleCellKeyDown,
    onColumnResized: (params: any) =>
      setSavedResizedColumnWidth(params, ref, section),
  };

  return {
    selectedRows,
    loading,
    isExporting: !!generating,
    gridColumns,
    gridColumnsReady: !!gridColumns,
    grid,
    summaryMatchingDialog,
    claimsExpandState: matching.claimsExpandState,
    trumpedMatchDialog: matching.trumpedMatchDialog,
    showMineClaims,
    searchText,
    onSearchChanged: setSearchText,
    onShowMineClaimsChange: setShowMineClaims,
    onResetFilters,
    onMatchGroupSelectedClaims: matching.onMatchGroupSelectedClaims,
    onAcceptMatches: matching.onAcceptMatches,
    onExport,
    refresh: () => {
      load(dispatch)();
      reloadGridData();
    },
    exportFileName: fileName,
    dataSourceLoading,
  };
}

export default useClaimsData;
