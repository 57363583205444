import { ColDef } from 'ag-grid-community';
import { ClaimRow, GridColumn } from 'api/endpoints';
import { ActionStatusCell } from '../../components/ActionStatusCell';
import { MatchCodeCell } from '../../components/MatchCodeCell';

import classNames from 'classnames';
import { ClaimReviewerCell } from '../../components/ClaimReviewerCell';
import { PinCell } from '../../components/PinCell';
import { SubMatchCodeCell } from '../../components/SubMatchCodeCell';
import { SectionType, SubsectionType } from '../../types';

import AgSetFloatingFilter from '../../components/AgSetFloatingFilter';

import { HyperlinkField } from 'pages/ClaimsContractsToolPage/components/HyperlinkField';
import {
  dateFilterOptions,
  numberFilterOptions,
  stringFilterOptions,
} from 'utils/agGrid/columnFilter';
import { CheckboxHeader } from '../../components/CheckboxHeader';
import { ClaimCounterpartyNameCell } from '../../components/ClaimCounterpartyNameCell';
import { PinHeader } from '../../components/PinHeader';
import {
  ACTION_STATUS_COLUMN,
  CLAIM_REVIEWER_COLUMN,
  CLAIMS_CONSTANT_COLUMNS,
  COUNTERPARTY_NAME_COLUMN,
  DATA_TYPE_MAP,
  DO_NOT_DISPLAY_COLUMNS,
  FORMATTING_CURRENCY,
  MATCH_CODE_COLUMN,
  PIN_COLUMN,
  REFERENCE_NUMBER_COLUMN,
  SUB_MATCH_CODE_COLUMN,
  HIDDEN_INPUT_COLUMNS,
  CURRENT_TOTAL_COLUMN,
  ADDRESS_COLUMN,
  CURRENT_DEBTOR_COLUMN,
} from '../../const';
import { getValueFormatterFn } from '../../grid/ClaimsGrid/ClaimsGrid.utils';
import { getTooltipValueGetterFn } from '../columnUtils';
import { MatchingColumnDefsCallbacks } from '../useMatching';

export interface ClaimsColumnDefsCallbacks extends MatchingColumnDefsCallbacks {
  refocusSelection?: () => unknown;
  onCheckboxChange?: (checked: boolean) => unknown;
}

const CHECKBOX_COLUMN_DEFITION: ColDef = {
  checkboxSelection: true,
  // field is absolutely neccessary because
  // otherwise ag-grid will assign "0" string to the field
  // and sometimes "1" string to the field
  // this will cause infinite update loops in useGridState
  field: 'checkbox',
  width: 60,
  maxWidth: 60,
  resizable: false,
  headerClass: 'checkbox',
  // https://stackoverflow.com/questions/57890959/header-checkbox-selection-doesnt-work-when-using-server-side-data-source
  // headerCheckboxSelection is not supported in server side model. It has to be
  // implemented manually
  // headerCheckboxSelection: true,
  headerComponent: CheckboxHeader,
  suppressColumnsToolPanel: true,
  menuTabs: [],
};
const PIN_COLUMN_DEFINITION: ColDef = {
  field: PIN_COLUMN,
  width: 50,
  maxWidth: 50,
  resizable: false,
  suppressColumnsToolPanel: true,
  menuTabs: [],
  headerComponent: PinHeader,
  cellRendererSelector: () => ({
    component: PinCell,
  }),
};

export function claimsStaticColumnDef(
  section: SectionType,
  subsection: SubsectionType,
  selectAllCheckbox: boolean,
  callbacks: ClaimsColumnDefsCallbacks,
): ColDef<ClaimRow>[] {

  if(section === SectionType.Claims){
    const checkboxColumnDefinition = {
      ...CHECKBOX_COLUMN_DEFITION,
      headerComponentParams: {
        section,
        subsection,
        checked: selectAllCheckbox,
        onChange: callbacks.onCheckboxChange,
      },
    };
    return [checkboxColumnDefinition, PIN_COLUMN_DEFINITION];
  } 

  return [];
}

export function claimsGridColumnMapper(
  section: SectionType,
  subsection: SubsectionType,
  callbacks: ClaimsColumnDefsCallbacks,
  selectedRowRef?: string
) {
  return (col: GridColumn): ColDef<ClaimRow> | undefined => {
    if (DO_NOT_DISPLAY_COLUMNS.includes(col.propertyName)) return;
    const isMatching = section === SectionType.Matching;

    const isNumeric = col.dataType === 'decimal';

    // custom components for the given cell
    var { cellRenderer, cellRendererParams } = getCellRenderer(
      col,
      section,
      subsection,
      callbacks,
      isMatching,
      selectedRowRef
    );

    var { filter, filterParams } = getFilter(col, isMatching);

    const valueFormatter = getValueFormatterFn(col.propertyName, col.dataType);
    const { initialWidth, minWidth } = isMatching ? getMatchingColumnWidth(col) : getColumnWidth(col);

    //use custom input filter component to hide it and only show floating filter button
    const isSetFilter = filter === 'agSetColumnFilter';
    const hasCustomFilter = !isMatching ? isSetFilter : (
      isSetFilter ||
      HIDDEN_INPUT_COLUMNS.includes(col.propertyName)
    );

    const result: ColDef<ClaimRow> = {
      field: col.propertyName,
      headerClass: col.propertyName,
      floatingFilterComponent:
        hasCustomFilter ? AgSetFloatingFilter : undefined,
      filter,
      sortable: true,
      cellClass: params => classNames(col.propertyName, {
        currency: col.formatting === FORMATTING_CURRENCY,
        'ag-right-aligned-cell': isNumeric,
        'row-class-editing': selectedRowRef && params.node.id !== selectedRowRef
      }),
      filterParams,
      initialWidth,
      minWidth,
      unSortIcon: true,
      menuTabs: [],
      lockVisible: CLAIMS_CONSTANT_COLUMNS.includes(col.propertyName),
      suppressMovable: CLAIMS_CONSTANT_COLUMNS.includes(col.propertyName),
      cellRendererParams,
      headerValueGetter: () => col.displayName,
      valueFormatter,
      tooltipValueGetter: getTooltipValueGetterFn(col),
      cellRendererSelector: cellRenderer
        ? () => ({ component: cellRenderer })
        : undefined,
    };

    return result;
  };
}

const getCellRenderer = (
  col: GridColumn,
  section: SectionType,
  subsection: SubsectionType,
  callbacks: ClaimsColumnDefsCallbacks,
  isMatching: boolean,
  selectedRowRef?: string,
): {
  cellRenderer?: (props: any) => JSX.Element | string;
  cellRendererParams?: {};
} => {
  if (col.propertyName === CLAIM_REVIEWER_COLUMN) {
    return {
      cellRenderer: ClaimReviewerCell,
    };
  }

  if (col.propertyName === COUNTERPARTY_NAME_COLUMN) {
    return {
      cellRenderer: ClaimCounterpartyNameCell,
      cellRendererParams: {
        section
      }
    };
  }

  if (col.propertyName === ACTION_STATUS_COLUMN) {
    return {
      cellRenderer: ActionStatusCell,
      cellRendererParams: {
        subsection,
      },
    };
  }

  if (col.propertyName === MATCH_CODE_COLUMN) {
    return {
      cellRenderer: MatchCodeCell,
      cellRendererParams: {
        isEditable: isMatching,
        subsection,
        onUpdateMatchCode: callbacks.onUpdateMatchSubMatchCode,
        onFetchNextMatchCode: callbacks.onFetchNextMatchCode,
        onLostFocus: callbacks.refocusSelection,
        selectedRowRef
      },
    };
  }

  if (col.propertyName === SUB_MATCH_CODE_COLUMN) {
    return {
      cellRenderer: SubMatchCodeCell,
      cellRendererParams: {
        isEditable: section !== SectionType.Contracts,
        onUpdateSubmatchCode: callbacks.onUpdateMatchSubMatchCode,
        onLostFocus: callbacks.refocusSelection,
        selectedRowRef
      },
    };
  }

  if (col.propertyName.includes('customUrl')) {
    return {
      cellRenderer: HyperlinkField,
      cellRendererParams: {
        propName: col.propertyName,
        section
      },
    };
  }

  return {};
};

function getFilter(
  col: GridColumn,
  isMatching: boolean,
) {
  let filter: string | undefined = DATA_TYPE_MAP[col.dataType];

  const filterParams: any = {
    applyMiniFilterWhileTyping: true,
    suppressFilterButton: isMatching && HIDDEN_INPUT_COLUMNS.includes(col.propertyName)
  };

  if (col.dataType === 'bool') {
    filterParams.values = [true, false];
  }

  if (col.dataType === 'enum') {
    filterParams.values = col.allowedValues;
  }

  if (['date', 'datetime'].includes(col.dataType)) {
    filterParams.filterOptions = dateFilterOptions;
  }

  if (['int', 'decimal'].includes(col.dataType)) {
    filterParams.filterOptions = numberFilterOptions;
  }

  if (col.dataType === 'string') {
    filterParams.filterOptions = stringFilterOptions;
  }

  return { filter, filterParams };
}

const getMatchingColumnWidth = (
  col: GridColumn,
): { initialWidth: number; minWidth?: number } => {
  
  if (col.propertyName === REFERENCE_NUMBER_COLUMN) {
    return { 
      initialWidth: 96,
      minWidth: 96
    };
  }

  if (col.propertyName === COUNTERPARTY_NAME_COLUMN) {
    return { 
      initialWidth: 200,
      minWidth: 200
    };
  }

  if (col.propertyName === MATCH_CODE_COLUMN) {
    return {
      initialWidth: 96,
      minWidth: 96
    };
  }

  if (col.propertyName === SUB_MATCH_CODE_COLUMN) {
    return {
      initialWidth: 130,
      minWidth: 130
    };
  }

  if (col.propertyName === ADDRESS_COLUMN) {
    return { 
      initialWidth: 160,
      minWidth: 160
    };
  }

  if (col.propertyName === CURRENT_TOTAL_COLUMN) {
    return { 
      initialWidth: 100,
      minWidth: 100
    };
  }

  if (col.propertyName === CURRENT_DEBTOR_COLUMN) {
    return { 
      initialWidth: 158,
      minWidth: 158
    };
  }

  if (col.dataType === 'enum') {
    return { initialWidth: 150, minWidth: 150 };
  }

  if (['int', 'decimal'].includes(col.dataType)) {
    return { initialWidth: 120 };
  }

  return { 
    initialWidth: 148,
    minWidth: 148
  };
};


const getColumnWidth = (
  col: GridColumn,
): { initialWidth: number; minWidth?: number } => {
  if (col.propertyName === REFERENCE_NUMBER_COLUMN) {
    return { initialWidth: 150 };
  }

  if (col.propertyName === COUNTERPARTY_NAME_COLUMN) {
    return { initialWidth: 320 };
  }

  if (col.propertyName === SUB_MATCH_CODE_COLUMN) {
    return { initialWidth: 120, minWidth: 120 };
  }

  if (col.propertyName === ACTION_STATUS_COLUMN) {
    return { initialWidth: 230, minWidth: 180 };
  }

  if (col.dataType === 'enum') {
    return { initialWidth: 150 };
  }

  if (['int', 'decimal'].includes(col.dataType)) {
    return { initialWidth: 120 };
  }

  if (col.dataType === 'bool') {
    return { initialWidth: 120 };
  }

  if (['date', 'datetime'].includes(col.dataType)) {
    return { initialWidth: 120 };
  }

  return { initialWidth: 200 };
};
