import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { useEffect } from 'react';
import { ExtendedGridOptions } from '../../types';

import { ApLoaderDefault } from '@alixpartners/ui-components';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-enterprise';
import {
  DEFAULT_COL_DEF,
  GRID_FLOATING_FILTER_HEIGHT,
  GRID_HEADER_HEIGHT_MATCHING,
  MATCHING_GRID_HEADER_HEIGHT,
  SIDE_BAR_CONFIG,
} from '../../const';
import '../ag-theme-alpine.css';
import './MatchingGrid.css';
import { getGridRowId } from '../ClaimsGrid/ClaimsGrid.utils';

export interface IProps {
  gridOptions: ExtendedGridOptions;
  dataSourceLoading?: boolean;
}

export const MatchingGrid = ({
  gridOptions,
  dataSourceLoading,
}: IProps) => {
  const { ref } = gridOptions;
  const gridApi = ref?.current?.api;

  useEffect(() => {
    if (!gridApi) return;
     if (!dataSourceLoading && !gridApi?.getRenderedNodes().length) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [gridApi, dataSourceLoading, gridOptions]);

  return (
    <div
      id="id-matching-grid"
      className={classNames('matching-tool-grid', 'ag-theme-alpine')}
    >
      <h1>Matching Table UI</h1>
      <AgGridReact
        suppressDragLeaveHidesColumns
        ref={ref}
        animateRows
        rowClass="matching-tool-grid__row"
        rowModelType="serverSide"
        serverSideInfiniteScroll
        serverSideSortOnServer
        suppressRowClickSelection
        rowHeight={MATCHING_GRID_HEADER_HEIGHT}
        enableBrowserTooltips // Added this option, because default AgGrid tooltip can't show new lines '\n' (need for ClaimReviewer column)
        loadingOverlayComponent={ApLoaderDefault}
        overlayNoRowsTemplate='No results matching the search'
        defaultColDef={{
          ...DEFAULT_COL_DEF,
          resizable: false,
        }}
        sideBar={SIDE_BAR_CONFIG}
        headerHeight={GRID_HEADER_HEIGHT_MATCHING}
        floatingFiltersHeight={GRID_FLOATING_FILTER_HEIGHT}
        getRowId={getGridRowId} // we have to set getRowId it to enable rows animations
        {...gridOptions}
      />
    </div>
  );
};
