import { useCallback, useRef, useState } from 'react';
import { ApIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './CellTextInput.css';

export interface CellTextInputProps {
  value?: string;
  tabIndex?: number;
  placeholder: string;
  editDisabled?: boolean;
  regenerateEnabled?: boolean;
  length?: number;
  onClick?: () => unknown;
  onChange: (value: string) => unknown;
  onLostFocus?: () => unknown;
  getNextValue?: () => Promise<any>;
}

enum CellTextInputMode {
  // placeholder is displayed here
  Empty = 'empty',
  // this is the mode when user clicked on the field but it is still empty
  // cancel cross is displayed in this mode
  EditingEmpty = 'editing-empty',
  //this is the mode when user is editing but hasn't changed any data yet
  Editing = 'editing',
  // this is the mode when user entered some data (or data has been autogenerated)
  Draft = 'draft',
  // this is the mode when data has been entered and confirm tick has been clicked
  Display = 'display',
}

const ICON_MAP = {
  [CellTextInputMode.Empty]: 'edit',
  [CellTextInputMode.EditingEmpty]: 'baseline_close',
  [CellTextInputMode.Editing]: 'baseline_close',
  [CellTextInputMode.Draft]: 'outline_check_circle_outline',
  [CellTextInputMode.Display]: 'edit',
};

let enterDown = false;

export const CellTextInput = ({
  placeholder,
  tabIndex,
  value,
  editDisabled,
  regenerateEnabled,
  length,
  onClick,
  onChange,
  onLostFocus,
  getNextValue,
}: CellTextInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const regenerateRef = useRef<HTMLButtonElement | null>(null);
  const iconRef = useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [iconMouseDown, setIconMouseDown] = useState(false);
  const [mode, setMode] = useState<CellTextInputMode>(
    value ? CellTextInputMode.Display : CellTextInputMode.Empty,
  );
  // we use number of clicks to ignore the first click
  // on the input and only request getNextValue for the form
  // on the second click

  const iconName = ICON_MAP[mode];

  const handleFocus = useCallback(() => {
    if (!currentValue) {
      setMode(CellTextInputMode.EditingEmpty);
    } else {
      setMode(
        currentValue === value ? CellTextInputMode.Editing : CellTextInputMode.Draft,
      );
    }
  }, [value, currentValue]);

  const handleBlur = useCallback(() => {
    if (iconMouseDown) return;
    if (enterDown) return;

    setCurrentValue(value);
    if (value) {
      setMode(
        value && value.length ? CellTextInputMode.Display : CellTextInputMode.Empty,
      );
    }
  }, [iconMouseDown, value]);

  const handleRegenerateClick = useCallback(async () => {
    if (loading) return;
    if (getNextValue) {
      setLoading(true);
      const nextValue = await getNextValue();
      setLoading(false);
      setCurrentValue(nextValue);
      onChange(nextValue);
    }
  }, [onChange, getNextValue, loading]);

  const handleIconClick = useCallback(() => {
    if (!ref.current) return;
    if (loading) return;
    switch (mode) {
      case CellTextInputMode.Empty:
      case CellTextInputMode.Display:
        if (!ref?.current) return;

        ref.current.focus();
        return;
      case CellTextInputMode.EditingEmpty:
        ref.current.blur();
        setCurrentValue(value);
        const inputMode = !value ? CellTextInputMode.Empty : CellTextInputMode.Display;
        setMode(inputMode);
        return;
      case CellTextInputMode.Editing:
        ref.current.blur();
        setMode(CellTextInputMode.Display);
        return;
      case CellTextInputMode.Draft:
        setLoading(true);
        setMode(CellTextInputMode.Display)
        if (currentValue) {
          onChange(currentValue);
        }
        return;
    }
  }, [currentValue, loading, mode, value, onChange, setLoading]);

  return (
    <div className={classNames('cell-text-input__container', mode)}>
      {regenerateEnabled &&
        [CellTextInputMode.Display, CellTextInputMode.Empty].includes(mode) && (
          <button
            ref={regenerateRef}
            tabIndex={tabIndex}
            className="cell-text-input__regenerate"
            onClick={handleRegenerateClick}
          >
            {loading ? (
              <ApLoaderDefault className="cell-text-input__loader" loaderSize="sm" />
            ) : (
              <ApIcon iconName={'replay'} iconSize="sm" />
            )}
          </button>
        )}
      <button
        ref={iconRef}
        tabIndex={tabIndex}
        className="cell-text-input__icon"
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            handleBlur();
            if (onLostFocus) onLostFocus();
          }
        }}
        // we should ignore blur clear action
        onMouseDown={() => setIconMouseDown(true)}
        onMouseUp={() => setIconMouseDown(false)}
        onClick={handleIconClick}
      >
        {loading && <ApLoaderDefault className="cell-text-input__loader" loaderSize="sm" />}
        {!editDisabled && <ApIcon iconName={iconName} />}
      </button>

      <input
        ref={ref}
        tabIndex={tabIndex}
        className="cell-text-input"
        placeholder={loading ? 'Loading' : placeholder}
        onClick={() => {
          if (mode === CellTextInputMode.Display && onClick) {
            onClick();
          }
        }}
        maxLength={length}
        onKeyDown={async (e) => {
          if (!ref.current) return;
          if (!iconRef.current) return;
          if (e.key === 'Enter') {
            enterDown = true;
            handleIconClick();
            ref.current.blur();
            enterDown = false;
            return;
          }
          if (e.key === 'Escape') {
            ref.current.blur();
            if (onLostFocus) onLostFocus();
            return;
          }

          if ((e.metaKey || e.ctrlKey) && e.key === 'c') {
            if (currentValue) {
              navigator.clipboard.writeText(currentValue);
            }
            e.preventDefault();
            return false;
          }
          if ((e.metaKey || e.ctrlKey) && e.key === 'x') {
            if (currentValue) {
              navigator.clipboard.writeText(currentValue);
              setCurrentValue('');
              setMode(CellTextInputMode.EditingEmpty);
            }
            e.preventDefault();
            return false;
          }

          if (editDisabled) {
            e.preventDefault();
            return false;
          }
          return true;
        }}
        onChange={(e) => {
          setCurrentValue(e.target.value);
          if (!e.target.value) {
            setMode(CellTextInputMode.EditingEmpty);
          } else {
            setMode(
              e.target.value === value
                ? CellTextInputMode.Editing
                : CellTextInputMode.Draft,
            );
          }
        }}
        onFocus={handleFocus}
        disabled={loading || editDisabled}
        onBlur={handleBlur}
        value={currentValue}
      />
    </div>
  );
};
