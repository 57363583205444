import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { ClaimRow } from 'api/endpoints';
import { ClaimMatchRow } from 'pages/SingleClaimPage/sections/SingleClaimMatches.hooks';

export interface ClaimMatchResult {
  claims: {
    items: ClaimMatchRow[];
    totalCount: number;
  };
}

const QUERY_FIELDS: (keyof ClaimRow)[] = [
  'referenceNumber',
  'counterpartyName',
  'currentSecured',
  'currentAdministrative',
  'currentPriority',
  'currentUnsecured',
  'currentTotal',
  'currentDebtor',
  'subMatchCode',
  'sourceType',
  'majorCategory',
  'minorCategory',
  'notMatched',
  'matchCode',
  'id',
];

const fieldParams = QUERY_FIELDS.join(' ');

const queryMatchCodeClaims = gql`
    query GetClaimsForMatchCode($matchCode: Int!) {
      claims(
        take: 1000,  
        where: {
          matchCode: { eq: $matchCode }
        }
      ) {
        totalCount
        items {
          ${fieldParams}
        }
      }
    }
  `;

const queryMatchCodeClaim = gql`
    query GetClaimForId($id: Int!) {
      claims(where: {
        id: { eq: $id }
      }) {
        items {
          ${fieldParams}
        }
      }
    }
  `;

export type MatchCodeClaim = { matchCode?: ClaimRow['matchCode'] };

export function useMatchCodeClaimsQuery(claim: MatchCodeClaim | undefined) {
  const matchCode = claim?.matchCode;
  return useQuery<ClaimMatchResult>(queryMatchCodeClaims, {
    variables: { matchCode },
    skip: !matchCode,
    fetchPolicy: 'no-cache'
  });
}

export const useLazyClaimQuery = () =>
  useLazyQuery<ClaimMatchResult>(queryMatchCodeClaim, {
    fetchPolicy: 'network-only',
  });
