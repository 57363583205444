import { ClaimEditValues } from 'api/endpoints';
import './SingleClaimMatches.css';

import classNames from 'classnames';
import { Section } from 'components/SingleClaim/Section';
import { isEmpty } from 'lodash';
import { TrumpedMatchClaimPopup } from 'pages/ClaimsContractsToolPage/components/TrumpedMatchClaimPopup/TrumpedMatchClaimPopup';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { assertUnreachable } from 'utils/assertUnreachable';
import { formatMoney } from 'utils/formatNumber';
import { useClaimToolQueryParams } from 'utils/hooks/useClaimToolQueryParams';
import { SubMatchCodeEdit } from '../components/SubMatchCodeEdit';
import {
  ClaimMatchRow,
  COLUMNS,
  useSingleClaimMatches,
} from './SingleClaimMatches.hooks';

interface IProps {
  claim: ClaimEditValues;
}

export const SingleClaimMatches = ({ claim }: IProps) => {
  const {
    headerClaim,
    matchedClaims,
    loading,
    trumpedMatchDialog,
    handleSubMatchCodeChange,
  } = useSingleClaimMatches(claim);
  return (
    <div>
      <Section
        className={classNames('single-claim-matches', {
          'anim-bg-pulse': loading,
          'utils-obstruct': loading,
        })}
        title={`Match summary for: ${claim.matchCode}`}
      >
        <table className="single-claim-matches-grid">
          <thead>
            <tr className="claim-matches-grid--header">
              {COLUMNS.map((col) => (
                <th key={col.field}>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {headerClaim && (
              <tr className="claim-matches-grid--row claim-matches-grid--title-row">
                {COLUMNS.map((col) => (
                  <td
                    className="claim-matches-grid--cell"
                    key={`$title-${col.field}`}
                  >
                    <GridCell
                      field={col.field}
                      claim={headerClaim}
                      onSubMatchCodeChange={handleSubMatchCodeChange}
                    />
                  </td>
                ))}
              </tr>
            )}

            {matchedClaims?.map((claimItem) => {
              return headerClaim?.referenceNumber !== claimItem.referenceNumber ? (
                <tr key={`${claimItem.id}`} className="claim-matches-grid--row">
                  {COLUMNS.map((col) => (
                    <td
                      className="claim-matches-grid--cell"
                      key={`${claimItem.id}-${col.field}`}
                    >
                      <GridCell
                        field={col.field}
                        claim={claimItem}
                        onSubMatchCodeChange={handleSubMatchCodeChange}
                      />
                    </td>
                  ))}
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </Section>

      {trumpedMatchDialog && (
        <TrumpedMatchClaimPopup
          claimId={trumpedMatchDialog.matchedClaims}
          onAccept={trumpedMatchDialog.onAccept}
          onClose={trumpedMatchDialog.onCancel}
        />
      )}
    </div>
  );
};

interface IGridFieldProps {
  field: keyof ClaimMatchRow;
  claim: ClaimMatchRow;
  readOnly?: boolean;
  onStatusChange?: (value: boolean, claim: ClaimMatchRow) => void;
  onSubMatchCodeChange?: (value: number, claim: ClaimMatchRow) => void;
}

const GridCell = ({
  field,
  claim,
  readOnly,
  onSubMatchCodeChange,
}: IGridFieldProps) => {
  const { buildSingleClaimPath, buildCounterpartyPath } = useClaimToolQueryParams();

  switch (field) {
    case 'counterpartyName':
      return (
        <div className="claim-matches-grid--cell-conterparty">
          <Link target="_blank" to={buildCounterpartyPath(claim.matchCode)}>
            {claim[field]}
          </Link>
        </div>
      );
    case 'referenceNumber':
      return (
        <div className="claim-matches-grid--cell-ref">
          <Link target="_blank" to={buildSingleClaimPath(claim.id)}>
            {claim[field]}
          </Link>
        </div>
      );
    case 'subMatchCode':
      return readOnly ? (
        <div className="claim-matches-grid--cell-value">{claim[field]}</div>
      ) : (
        <SubmatchGridCell
          disabled={claim.notMatched}
          claim={claim}
          onSubMatchCodeChange={onSubMatchCodeChange}
        />
      );
    case 'id':
    case 'majorCategory':
    case 'minorCategory':
    case 'notMatched':
    case 'sourceType':
    case 'currentDebtor':
    case 'matchCode':
      return <div className="claim-matches-grid--cell-value">{claim[field]}</div>;
    case 'currentAdministrative':
    case 'currentPriority':
    case 'currentSecured':
    case 'currentTotal':
    case 'currentUnsecured':
      return (
        <div className="claim-matches-grid--cell-value">
          {formatMoney(claim[field])}
        </div>
      );
    default:
      return assertUnreachable(field);
  }
};

function SubmatchGridCell({
  claim,
  disabled,
  onSubMatchCodeChange,
}: {
  claim: ClaimMatchRow;
  disabled?: boolean;
  onSubMatchCodeChange?: (value: number, claim: ClaimMatchRow) => void;
}) {
  const [editedValue, setSubmatchCode] = useState('');

  useEffect(() => {
    setSubmatchCode(`${claim.subMatchCode ?? ''}`);
  }, [claim]);

  const submitValue = useCallback(() => {
    if (!editedValue) {
      setSubmatchCode(`${claim.subMatchCode ?? ''}`);
      return;
    }

    const newValue = +editedValue;
    if (newValue && newValue !== claim.subMatchCode) {
      onSubMatchCodeChange?.(newValue, claim);
    }
  }, [claim, editedValue, onSubMatchCodeChange]);

  const handleSubmatchInputBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      submitValue();
    },
    [submitValue],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') submitValue();
    },
    [submitValue],
  );

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value.trim();
    if (isEmpty(value) || Number.isInteger(+value)) {
      setSubmatchCode(value);
    }
  }, []);

  return (
    <SubMatchCodeEdit
      disabled={disabled}
      onBlur={handleSubmatchInputBlur}
      className="claim-matches-grid--cell-submatchcode"
      value={editedValue}
      onKeyDown={handleKeyDown}
      onChange={handleOnChange}
    />
  );
}
