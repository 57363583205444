import { IFilterParams, IFloatingFilterParams } from 'ag-grid-community';
import { Fragment, forwardRef } from 'react';

interface FilterParams extends IFilterParams {
  suppressFilterButton: boolean;
}

interface FloatingFilterParams extends IFloatingFilterParams {
  filterParams: FilterParams
}

export default forwardRef((props: FloatingFilterParams, ref) => {
  if (props.filterParams.suppressFilterButton) return null;
  return (
    <Fragment>
      <div
        className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper filter-set-column-filter"
        role="presentation"
        onClick={() => props.showParentFilter()}
      >
        <input
          className="ag-input-field-input ag-text-field-input"
          type="text"
          disabled={true}
          id="ag-53-input"
          placeholder="Click to Select"
        />
      </div>
    </Fragment>
  );
});
